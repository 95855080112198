import { useMemo, useRef } from 'react';
import {
  StationeryTemplateCategoryEnum,
  useCardDashboardStationeryTemplatesContainerQuery,
  StationeryDesignGalleryFilterTagsFragment,
  usePrintDesignsGalleryEventInfoByEventHandleQuery
} from '@graphql/generated';

import { useManagedSearchParam } from '@apps/admin/common/hooks/useManagedSearchParam';
import { useCardCategoryContext } from '../CardCategoryProvider';
import { useFeatureValue } from '@shared/core/featureFlags';
import { ThemeJson } from '@apps/card/routes/CardCustomizer';
import { StationeryTemplate } from './DesignsGallery.types';
import { BASIC_TEMPLATE_STATIONERY_TAG, PREMIUM_TEMPLATE_STATIONERY_TAG, WITHOUT_PHOTO_STATIONERY_TAG, processInitialQueryData } from './utils';
import { useApplyFiltersToTemplates } from './useApplyFiltersToTemplates';
import { FilterFacet } from './components/Filters/Filter.types';
import { useActivePrintFiltersVar } from './utils/activePrintFiltersCache';
import { useTranslation } from '@shared/core';

type UseDesignTemplatesArgs = {
  /**
   * Marking as optional to prepare for the future when card app is no longer tied to the admin view
   */
  eventId?: string;
  eventHandle?: string;
};

type ThemeIdLookup = {
  [key: string]: {
    position: number;
    themeId: string;
  };
};

export type StationeryTemplateWithThemeJson = StationeryTemplate & { themeJSON: ThemeJson };
/*
 * This method allows setting order of top N items in the gallery based on array of themeIds coming from Amplitude Feature flag
 */
const setGalleryOrder = (templates: StationeryTemplate[], themeOrder: string[]): StationeryTemplate[] => {
  if (templates?.length < 1 || themeOrder?.length < 1) return templates;
  const orderedTemplates = [...templates];
  const themeLookup: ThemeIdLookup = {};
  const templateThemeIdSet = new Set(templates.map(template => template.themeId));
  const validatedThemeOrder = themeOrder.filter(themeId => templateThemeIdSet.has(themeId));
  validatedThemeOrder.forEach((themeId, index) => {
    themeLookup[themeId] = { themeId: themeId, position: validatedThemeOrder.length - index };
  });

  try {
    orderedTemplates.sort((a, b) => {
      const aIndex = themeLookup[a?.themeId]?.position ?? -1;
      const bIndex = themeLookup[b?.themeId]?.position ?? -1;
      return bIndex - aIndex;
    });
  } catch {
    console.error('Error sorting templates by printGalleryThemeDisplayOrder.  Using default sort order');
  }

  return orderedTemplates;
};

const useFilterFacets = ({ stationeryTags, templates }: { stationeryTags: Maybe<StationeryDesignGalleryFilterTagsFragment[]>; templates: StationeryTemplate[] }) => {
  const { t } = useTranslation('stationery');
  const tFilters = t('dashboard', 'designsGallery', 'filters');

  const activePrintFilters = useActivePrintFiltersVar();
  const { tagNameToTemplates, photoTemplateCounts, premiumTemplateCounts } = useMemo(() => {
    const tagNameToTemplates: Record<string, StationeryTemplate[]> = {};
    // Need to manually count the number of templates that support + don't support photos
    const photoTemplateCounts = {
      with: 0,
      without: 0
    };

    const premiumTemplateCounts = {
      premium: 0,
      basic: 0
    };

    templates.forEach(template => {
      let templateSupportsPhoto = false;
      template.tags.forEach(tag => {
        if (tag === 'photo') {
          // Because there are more templates that aren't photo compared to those that are,
          // we only tagged the ones that have photo with `photo` tag.
          templateSupportsPhoto = true;
        }
        if (!tagNameToTemplates[tag]) {
          tagNameToTemplates[tag] = [];
        }
        tagNameToTemplates[tag].push(template);
      });

      if (templateSupportsPhoto) {
        photoTemplateCounts.with++;
      } else {
        photoTemplateCounts.without++;
      }

      if (template.premium || template.format === 'paper') {
        premiumTemplateCounts.premium++;
      } else {
        premiumTemplateCounts.basic++;
      }
    });

    return { tagNameToTemplates, photoTemplateCounts, premiumTemplateCounts };
  }, [templates]);

  const { filterFacets } = useMemo(() => {
    const categoryToFacets: Record<string, FilterFacet> = {};
    stationeryTags?.forEach(tag => {
      if (tag.category === 'Styles' || tag.category === 'Colors' || tag.category === 'Photo') {
        if (!categoryToFacets[tag.category]) {
          categoryToFacets[tag.category] = {
            groupCode: tag.category,
            label: tag.category === 'Photo' ? 'Photo' : tag.category === 'Styles' ? 'Style' : 'Color',
            options: []
          };
        }

        const compositeCode = `${tag.category}:${tag.name}`;

        if (tagNameToTemplates[tag.name]?.length > 0) {
          if (tag.category === 'Photo') {
            // Special handling for photo category since non-photo templates are not tagged as such
            categoryToFacets[tag.category].options.push({
              compositeCode,
              isActive: !!activePrintFilters[compositeCode],
              label: tFilters.photo.with(),
              matchingItemCount: photoTemplateCounts.with
            });
            categoryToFacets[tag.category].options.push({
              compositeCode: WITHOUT_PHOTO_STATIONERY_TAG,
              isActive: !!activePrintFilters[WITHOUT_PHOTO_STATIONERY_TAG],
              label: tFilters.photo.without(),
              matchingItemCount: photoTemplateCounts.without
            });
          } else {
            categoryToFacets[tag.category].options.push({
              compositeCode: compositeCode,
              isActive: !!activePrintFilters[compositeCode],
              label: tag.displayLabel,
              matchingItemCount: tagNameToTemplates[tag.name]?.length || 0
            });
          }
        }
      }
    });

    categoryToFacets['Price'] = {
      groupCode: 'Price',
      label: tFilters.price.label(),
      options: [
        {
          compositeCode: PREMIUM_TEMPLATE_STATIONERY_TAG,
          isActive: !!activePrintFilters[PREMIUM_TEMPLATE_STATIONERY_TAG],
          label: tFilters.price.premium(),
          matchingItemCount: premiumTemplateCounts.premium
        },
        {
          compositeCode: BASIC_TEMPLATE_STATIONERY_TAG,
          isActive: !!activePrintFilters[BASIC_TEMPLATE_STATIONERY_TAG],
          label: tFilters.price.basic(),
          matchingItemCount: premiumTemplateCounts.basic
        }
      ]
    };

    Object.entries(categoryToFacets).forEach(([category, facet]) => {
      facet.options.sort((a, b) => {
        const aLabel = a.label.toLowerCase();
        const bLabel = b.label.toLowerCase();
        return aLabel < bLabel ? -1 : aLabel > bLabel ? 1 : 0;
      });
    });

    return {
      filterFacets: [categoryToFacets['Styles'], categoryToFacets['Colors'], categoryToFacets['Photo'], categoryToFacets['Price']].filter(
        maybeFacet => !!maybeFacet && maybeFacet.options.length > 0
      ) as FilterFacet[]
    };
  }, [
    stationeryTags,
    tFilters.price,
    tFilters.photo,
    activePrintFilters,
    premiumTemplateCounts.premium,
    premiumTemplateCounts.basic,
    tagNameToTemplates,
    photoTemplateCounts.with,
    photoTemplateCounts.without
  ]);

  return { filterFacets };
};

export const useDesignTemplates = (args: UseDesignTemplatesArgs) => {
  const { eventId, eventHandle } = args;
  const { currentCategory } = useCardCategoryContext();
  const [themeQueryParam] = useManagedSearchParam('theme');
  const themeQueryParamRef = useRef(themeQueryParam);
  const { value, payload } = useFeatureValue('printGalleryThemeDisplayOrder');

  const { applyFiltersToTemplates, deriveFilterCountFromTemplates } = useApplyFiltersToTemplates();

  const { data: eventInfoData } = usePrintDesignsGalleryEventInfoByEventHandleQuery({
    batchMode: 'fast',
    variables: {
      eventHandle: eventHandle || ''
    },
    fetchPolicy: 'cache-first',
    skip: !eventHandle
  });

  const shouldSkipFetchingDigitalDesignData = !(currentCategory === StationeryTemplateCategoryEnum.invitation || currentCategory === StationeryTemplateCategoryEnum.saveTheDate);

  const eventDesignThemeId = eventInfoData?.eventByName?.eventDesign?.theme.themeId;

  const { data: templateData } = useCardDashboardStationeryTemplatesContainerQuery({
    variables: {
      filter: {
        categories: [currentCategory],
        side: 'front'
      },
      // Requesting a number larger than what we have in the DB while we wait on
      // designs for pagination
      first: 20000,
      skipDigitalDesigns: shouldSkipFetchingDigitalDesignData,
      includeOptions: {
        includeFavoritesByEventIds: eventId ? [eventId] : undefined
      }
    },
    batchMode: 'fast',
    fetchPolicy: 'cache-first'
  });

  const templates = useMemo(() => {
    const mappedTemplates = processInitialQueryData(templateData, currentCategory);
    let sortedTemplates: StationeryTemplate[] = [...mappedTemplates];

    if (value === 'on' && Array.isArray(payload)) {
      sortedTemplates = setGalleryOrder(mappedTemplates, payload);
    }

    if (themeQueryParamRef.current || eventDesignThemeId) {
      // Sorting in place since we're already creating a new array above when we map the templates

      sortedTemplates.sort((a, b) => {
        // Prioritize the theme query param
        if (a.themeId === themeQueryParamRef.current) {
          return -1;
        } else if (b.themeId === themeQueryParamRef.current) {
          return 1;
        }

        // If no theme query param, prioritize the event design theme
        if (a.themeId === eventDesignThemeId) {
          return -1;
        } else if (b.themeId === eventDesignThemeId) {
          return 1;
        }

        return 0;
      });
    }

    return sortedTemplates;
  }, [templateData, currentCategory, eventDesignThemeId, value, payload]);

  const { filterFacets } = useFilterFacets({ stationeryTags: templateData?.stationeryTags, templates });

  return {
    filterMatchCounts: deriveFilterCountFromTemplates(templates),
    templates: applyFiltersToTemplates(templates),
    filterFacets,
    eventDesignThemeId
  };
};
