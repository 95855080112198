import React, { useState } from 'react';
import { useTranslation } from '@shared/core';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { Category, PointsOnMapFragment } from '@graphql/generated';
import { Flex, ButtonV2, TextV2, Box } from '@withjoy/joykit';
import { useLayout } from '@apps/guest/packages/layout-engine/layouts/LayoutProvider';
import { AccommodationCombined } from '../Accommodations/Accommodation.types';
import { pxToRem } from '@withjoy/joykit/theme';
import { createDateFromUnformattedString } from '@shared/utils/useDateUtils';
import { distanceInMiles } from '@shared/utils/distanceInMiles';
import { DEFAULT_HOTEL_IMAGE } from '../../TravelMap.constants';
import { SkeletonText, SkeletonThumbnail } from '@shared/components/Skeleton';
import { useFeatureValue } from '@shared/core/featureFlags';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import {
  body1ToParagraphOverride,
  button2ToButtonsOverride,
  hed2ToSubHeadingOverride,
  hed4ToSubHeadingOverride,
  label3ToParagraphOverride
} from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { boxShadowValuesV2 } from '@shared/utils/style/dropShadows';
import { TextEllipsisWrapper } from '@shared/utils/formatting/textEllipsis';
import { useIsTextOverflowing } from '../Accommodations/hooks/useTextLineClampOverflow';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

interface AccommodationsTileV2FavoriteProps extends AccommodationCombined {
  locationInfo?: PointsOnMapFragment;
  eventHandle: string;
  isFirst: boolean;
  isLast: boolean;
  showDefaultData?: boolean;
  onSelectHotel: (id: string) => void;
  setLocationBeingHovered?: (id: string | undefined) => void;
}

const AccommodationsTileV2Favorite: React.FC<AccommodationsTileV2FavoriteProps> = props => {
  const { layout } = useLayout();
  const {
    displayName,
    description,
    photo,
    cutoffDate,
    strikeoutPricePerNight,
    pricePerNight,
    id,
    showDefaultData,
    locationInfo,
    numberOfRoomsBooked,
    interestedGuestCount,
    setLocationBeingHovered,
    onSelectHotel
  } = props;

  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const isMobileOrTablet = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'md' }));
  const { scaleFontSize } = useScaleGuestSiteFontSize();
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';
  const accommodationsGuestButtonExperimentEnabled = useFeatureValue('accommodationsGuestButtonExperiment').value === 'treatment';

  const isBrannan = layout === 'brannan';
  const isGridView = isBrannan && !isMobile;

  const haveDistanceToVenue = locationInfo?.latitude && locationInfo?.longitude && props.latitude && props.longitude;
  const milesToVenue = distanceInMiles(locationInfo?.latitude || 0, locationInfo?.longitude || 0, props.latitude || 0, props.longitude || 0).toFixed(1);

  const { textRef, isOverflowing } = useIsTextOverflowing(2);
  const [isCoupleNoteOpen, setIsCoupleNoteOpen] = useState(false);

  const handleOnSelectHotel = useEventCallback(e => {
    e.preventDefault();
    onSelectHotel(id);
  });

  const handleCoupleNoteOpen = useEventCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setIsCoupleNoteOpen(isOpen => !isOpen);
  });

  return (
    <Flex
      width={'100%'}
      justifyContent="center"
      padding={6}
      borderRadius={pxToRem(isGridView ? 24 : 12)}
      onClick={handleOnSelectHotel}
      cursor="pointer"
      _hover={{ boxShadow: '0px 7px 27px 0px rgba(44, 41, 37, 0.06), 0px 4px 16px 0px rgba(0, 0, 0, 0.07)' }}
      onMouseEnter={() => !isMobileOrTablet && setLocationBeingHovered?.(id)}
      onMouseLeave={() => !isMobileOrTablet && setLocationBeingHovered?.(undefined)}
    >
      <Flex width={'100%'} position="relative">
        <Flex justifyContent="space-between" position="absolute" top={4} left={4} right={4} zIndex={1} flexWrap="wrap" gap={4}>
          <Flex justifyContent="center" alignItems="center" columnGap={2} width={32} height={32} borderRadius="50%" backgroundColor="white" boxShadow={boxShadowValuesV2.xxxl}>
            <GuestSiteTypographyOverride override={label3ToParagraphOverride} defaultFontFamily="Inter UI">
              <TextV2 typographyVariant="label3" fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)} fontWeight={500} fontFamily="Inter UI" color="mono14">
                1
              </TextV2>
            </GuestSiteTypographyOverride>
          </Flex>
          {interestedGuestCount && interestedGuestCount > 1 ? (
            <Flex
              backgroundColor="white"
              boxShadow="0px 7px 27px 0px rgba(44, 41, 37, 0.06), 0px 4px 16px 0px rgba(0, 0, 0, 0.07)"
              borderRadius={pxToRem(20.632)}
              height={pxToRem(32)}
              alignItems="center"
              paddingX={4}
            >
              <GuestSiteTypographyOverride override={label3ToParagraphOverride} defaultFontFamily="Inter UI">
                <TextV2
                  fontFamily={'Inter UI'}
                  color={'black'}
                  fontSize={scaleFontSize(pxToRem(13), Category.PARAGRAPH)}
                  letterSpacing={pxToRem(-0.032)}
                  fontWeight={600}
                  alignSelf={'center'}
                  typographyVariant="label3"
                >
                  {accTrans.guestsInterested({ interestedGuestCount })}
                </TextV2>
              </GuestSiteTypographyOverride>
            </Flex>
          ) : null}
        </Flex>
        <Flex width={'100%'} flexDirection={'column'} gap={6}>
          <Box
            width="100%"
            height={pxToRem(isGridView ? 324 : 206)}
            borderRadius={pxToRem(isGridView ? 24 : 12)}
            objectFit={'cover'}
            backgroundSize={'cover'}
            backgroundPosition={`center ${showDefaultData ? 'bottom' : 'center'}`}
            backgroundRepeat={'no-repeat'}
            backgroundImage={`url(${photo?.url || DEFAULT_HOTEL_IMAGE})`}
            display="flex"
            position={'relative'}
          />
          <Flex flexDirection={isGridView ? 'row' : 'column'} justifyContent={{ _: 'center', sm2: isBrannan ? 'space-between' : 'center' }} gap={6}>
            <Flex flexDirection="column" gap={6}>
              <Flex flexDirection="column" gap={2}>
                <Flex flexDirection="column" gap={1}>
                  <GuestSiteTypographyOverride override={hed2ToSubHeadingOverride} defaultFontFamily="Inter UI">
                    <TextEllipsisWrapper
                      typographyVariant="hed2"
                      lineClamp={2}
                      {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                      fontSize={scaleFontSize(pxToRem(22), Category.SUB_HEADING)}
                      fontWeight={600}
                      color="mono14"
                      lineHeight={'28.6px'}
                      letterSpacing={'-0.528px'}
                      title={displayName || ''}
                    >
                      {displayName}
                    </TextEllipsisWrapper>
                  </GuestSiteTypographyOverride>
                  <Flex width="100%" alignItems="flex-end">
                    {description && (
                      <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
                        <TextEllipsisWrapper ref={textRef} lineClamp={isCoupleNoteOpen ? 'none' : 2}>
                          <TextV2
                            typographyVariant="body1"
                            {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                            fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                            fontWeight={400}
                            color="mono14"
                            title={description}
                          >
                            {description}
                          </TextV2>
                        </TextEllipsisWrapper>
                      </GuestSiteTypographyOverride>
                    )}
                    {isOverflowing && (
                      <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
                        <TextV2
                          {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                          fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                          fontWeight={700}
                          _hover={{ cursor: 'pointer' }}
                          color="mono12"
                          onClick={handleCoupleNoteOpen}
                          typographyVariant="body1"
                        >
                          {isCoupleNoteOpen ? `${accTrans.less()}` : `${accTrans.more()}`}
                        </TextV2>
                      </GuestSiteTypographyOverride>
                    )}
                  </Flex>
                </Flex>
                <Flex gap={{ _: 1, sm2: 2 }} alignItems="center">
                  {haveDistanceToVenue && (
                    <>
                      <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
                        <TextV2
                          typographyVariant={'body1'}
                          {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                          fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                          color="mono12"
                          letterSpacing="-0.02em"
                        >
                          {accTrans.milesAway({ miles: milesToVenue })}
                        </TextV2>
                      </GuestSiteTypographyOverride>
                      <TextV2 typographyVariant="body1" color="mono12" letterSpacing="-0.02em">
                        •
                      </TextV2>
                    </>
                  )}
                  {numberOfRoomsBooked && (
                    <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
                      <TextV2
                        typographyVariant={'body1'}
                        {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                        fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                        color="mono12"
                        letterSpacing="-0.02em"
                      >
                        {accTrans.reservedRooms({ rooms: numberOfRoomsBooked })}
                      </TextV2>
                    </GuestSiteTypographyOverride>
                  )}
                </Flex>
                {cutoffDate && (
                  <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
                    <TextV2
                      typographyVariant={'body1'}
                      {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                      fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                      color="accentBlue4"
                      letterSpacing="-0.02em"
                    >
                      {accTrans.beforeDate({
                        date: createDateFromUnformattedString(cutoffDate || '').toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })
                      })}
                    </TextV2>
                  </GuestSiteTypographyOverride>
                )}
              </Flex>
              {pricePerNight && !isGridView && (
                <Flex gap={2} justifyContent="flex-end">
                  {strikeoutPricePerNight && (
                    <GuestSiteTypographyOverride override={hed4ToSubHeadingOverride} defaultFontFamily="Inter UI">
                      <TextV2
                        typographyVariant={'hed4'}
                        {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                        fontSize={scaleFontSize(pxToRem(18), Category.SUB_HEADING)}
                        color="mono10"
                        fontWeight={600}
                        textAlign="right"
                        backgroundSize={'100% 1.5px'}
                        width={'auto'}
                        backgroundImage={'linear-gradient(currentColor, currentColor)'}
                        backgroundPosition={'0% 50%'}
                        backgroundRepeat={'no-repeat'}
                      >
                        ${strikeoutPricePerNight.toString()}
                      </TextV2>
                    </GuestSiteTypographyOverride>
                  )}
                  <GuestSiteTypographyOverride override={hed4ToSubHeadingOverride} defaultFontFamily="Inter UI">
                    <TextV2
                      typographyVariant={'hed4'}
                      {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                      fontSize={scaleFontSize(pxToRem(18), Category.SUB_HEADING)}
                      color="mono14"
                      fontWeight={600}
                      textAlign="right"
                    >
                      ${pricePerNight}
                    </TextV2>
                  </GuestSiteTypographyOverride>
                  <GuestSiteTypographyOverride override={hed4ToSubHeadingOverride} defaultFontFamily="Inter UI">
                    <TextV2
                      typographyVariant={'hed4'}
                      {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                      fontSize={scaleFontSize(pxToRem(18), Category.SUB_HEADING)}
                      color="mono14"
                      fontWeight={400}
                      textAlign="right"
                      textTransform="none"
                    >
                      {accTrans.perNight()}
                    </TextV2>
                  </GuestSiteTypographyOverride>
                </Flex>
              )}
              {accommodationsGuestButtonExperimentEnabled && (
                <GuestSiteTypographyOverride override={button2ToButtonsOverride} defaultFontFamily="Inter UI">
                  <ButtonV2
                    width={{ _: '100%', sm2: isBrannan ? pxToRem(278) : '100%' }}
                    intent="neutral"
                    shape="rounded"
                    fontWeight={600}
                    backgroundColor="black"
                    fontSize={scaleFontSize(pxToRem(16), Category.BUTTONS)}
                    {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                    onMouseEnter={() => !isMobileOrTablet && setLocationBeingHovered?.(id)}
                    onMouseLeave={() => !isMobileOrTablet && setLocationBeingHovered?.(undefined)}
                    onClick={handleOnSelectHotel}
                  >
                    {isBrannan ? accTrans.view() : accTrans.viewMore()}
                  </ButtonV2>
                </GuestSiteTypographyOverride>
              )}
            </Flex>
            {pricePerNight && isGridView && (
              <Flex flexDirection="column" gap={2}>
                <Flex gap={2} alignItems="center">
                  {strikeoutPricePerNight && (
                    <GuestSiteTypographyOverride override={hed4ToSubHeadingOverride} defaultFontFamily="Inter UI">
                      <TextV2
                        typographyVariant={'hed4'}
                        {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                        fontSize={scaleFontSize(pxToRem(22), Category.SUB_HEADING)}
                        color="mono10"
                        fontWeight={600}
                        textAlign="right"
                        backgroundSize={'100% 1.5px'}
                        width={'auto'}
                        backgroundImage={'linear-gradient(currentColor, currentColor)'}
                        backgroundPosition={'0% 50%'}
                        backgroundRepeat={'no-repeat'}
                      >
                        ${strikeoutPricePerNight.toString()}
                      </TextV2>
                    </GuestSiteTypographyOverride>
                  )}
                  <GuestSiteTypographyOverride override={hed4ToSubHeadingOverride} defaultFontFamily="Inter UI">
                    <TextV2
                      typographyVariant={'hed4'}
                      {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                      fontSize={scaleFontSize(pxToRem(32), Category.SUB_HEADING)}
                      color="mono14"
                      fontWeight={600}
                      textAlign="right"
                    >
                      ${pricePerNight}
                    </TextV2>
                  </GuestSiteTypographyOverride>
                </Flex>
                <GuestSiteTypographyOverride override={hed4ToSubHeadingOverride} defaultFontFamily="Inter UI">
                  <TextV2
                    typographyVariant={'hed4'}
                    {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                    fontSize={scaleFontSize(pxToRem(17), Category.SUB_HEADING)}
                    color="mono12"
                    fontWeight={400}
                    textAlign="right"
                    textTransform="none"
                  >
                    {accTrans.perNight()}
                  </TextV2>
                </GuestSiteTypographyOverride>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

AccommodationsTileV2Favorite.displayName = 'AccommodationsTileV2Favorite';

const AccommodationsTileV2FavoriteSkeleton: React.FC = () => {
  return (
    <>
      <SkeletonThumbnail
        height={pxToRem(324)}
        overrides={{
          PlaceholderRoot: {
            props: {
              width: '100%',
              backgroundColor: 'white',
              borderRadius: '12px !important'
            }
          }
        }}
      />
      <Box width="100%" paddingTop={6}>
        <SkeletonText rows={1} width={pxToRem(200)} skeletonHeight={pxToRem(29)} paddingBottom={2} />
        <SkeletonText rows={1} width={pxToRem(278)} skeletonHeight={pxToRem(23)} paddingBottom={2} />
        <SkeletonText rows={1} width={pxToRem(250)} skeletonHeight={pxToRem(23)} paddingBottom={6} />
        <SkeletonText width={pxToRem(278)} rows={1} skeletonHeight={pxToRem(48)} />
      </Box>
    </>
  );
};

export { AccommodationsTileV2Favorite, AccommodationsTileV2FavoriteSkeleton };
