import React from 'react';
import { ButtonV2, DialogV2, DrawerV2, Flex, TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { StationeryTemplateIdentifiers } from './types';
import { OnlyValidCountriesShippingWarningMessage } from './components/OnlyUSShippingWarningMessage';
import { StationeryDraftFormat } from '@graphql/generated';

interface RestrictedInternationalShippingWarningDialogProps {
  isOpen: boolean;
  templateIdentifiers: StationeryTemplateIdentifiers | null;
  handleOnClose: () => void;
  handleOnClick: () => void;
  draftFormat: StationeryDraftFormat;
}

const BodyContent: React.FC<RestrictedInternationalShippingWarningDialogProps> = props => {
  const { templateIdentifiers, draftFormat } = props;

  return (
    <Flex flexDirection="column" paddingY="32px" paddingX={{ _: '8px', md: '32px' }} alignItems="flex-start" justifyContent="center">
      <OnlyValidCountriesShippingWarningMessage
        templateIdentifiers={templateIdentifiers}
        telemetryDialogId="restrictedInternationalShippingWarningDialog"
        draftFormat={draftFormat}
      />
    </Flex>
  );
};

const DesktopView: React.FC<RestrictedInternationalShippingWarningDialogProps> = props => {
  const { isOpen, handleOnClose, handleOnClick } = props;
  const { t } = useTranslation('stationery');
  const translations = t('dashboard', 'restrictedInternationalShippingWarningDialog', props.draftFormat);

  return (
    <DialogV2
      size="xl"
      disableAutoFocus
      isOpen={isOpen}
      onClose={handleOnClose}
      overrides={{
        Body: {
          props: {
            paddingX: 0,
            paddingY: 0
          }
        },
        Header: { props: { borderBottom: '1px solid #EBEBEB' } },
        CloseButton: {
          props: {
            color: 'black',
            top: 4,
            right: 4,
            padding: 0,
            borderRadius: '100%'
          }
        }
      }}
    >
      <DialogV2.Header>
        <Flex justifyContent="space-between" alignItems="center">
          <TextV2 typographyVariant="hed6" fontWeight="semibold">
            {translations.title()}
          </TextV2>
          <DialogV2.CloseButton />
        </Flex>
      </DialogV2.Header>
      <DialogV2.Body>
        <BodyContent {...props} />
      </DialogV2.Body>
      <DialogV2.Footer borderTop="1px solid #EBEBEB" alignItems="center" justifyContent="center">
        <ButtonV2 onClick={handleOnClick} intent="neutral" shape="rounded">
          {translations.ctaText()}
        </ButtonV2>
      </DialogV2.Footer>
    </DialogV2>
  );
};

const MobileView: React.FC<RestrictedInternationalShippingWarningDialogProps> = props => {
  const { isOpen, handleOnClose, handleOnClick } = props;
  const { t } = useTranslation('stationery');
  const translations = t('dashboard', 'restrictedInternationalShippingWarningDialog', props.draftFormat);

  return (
    <DrawerV2
      anchor="bottom"
      size="auto"
      disableAutoFocus
      isOpen={isOpen}
      onClose={handleOnClose}
      overrides={{
        Content: {
          props: {
            borderTopRightRadius: 3,
            borderTopLeftRadius: 3
          }
        },
        Body: {
          props: {
            paddingX: 0,
            paddingY: 0
          }
        },
        CloseButton: {
          props: {
            color: 'black',
            top: 4,
            right: 4,
            backgroundColor: 'white',
            padding: 0,
            borderRadius: '100%'
          }
        }
      }}
    >
      <DrawerV2.Header borderBottom="1px solid #EBEBEB">
        <Flex justifyContent="space-between" alignItems="center" columnGap="12px">
          <TextV2 typographyVariant="hed3" fontWeight="semibold" width="274px">
            {translations.title()}
          </TextV2>
          <DrawerV2.CloseButton />
        </Flex>
      </DrawerV2.Header>
      <DrawerV2.Body>
        <BodyContent {...props} />
      </DrawerV2.Body>
      <DrawerV2.Footer borderTop="1px solid #EBEBEB">
        <ButtonV2 onClick={handleOnClick} intent="neutral" shape="rounded" fullWidth={true}>
          {translations.ctaText()}
        </ButtonV2>
      </DrawerV2.Footer>
    </DrawerV2>
  );
};

export const RestrictedInternationalShippingWarningDialog: React.FC<RestrictedInternationalShippingWarningDialogProps> = props => {
  const isMobileOrTablet = useIsMobileOrTablet();

  return isMobileOrTablet ? <MobileView {...props} /> : <DesktopView {...props} />;
};
