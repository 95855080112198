import { useMemo } from 'react';
import { AccommodationCombined, AccommodationCombinedType } from '../components/Accommodations/Accommodation.types';
import { withWindow } from '@shared/utils/withWindow';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useCustomPageTelemetry } from '../../../Custom.telemetry';
import { useTranslation } from '@shared/core';
import { useHotelBookingRoutePaths } from '@apps/guest/routes/HotelBooking/HotelBooking.routes';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { useIncrementInterestedGuestCountMutation } from '@graphql/generated';
import { useAccommodationPlaceRoutePaths } from '@apps/guest/routes/HotelBooking/routes/AccommodationPlacePartner/AccommodationPlacePartner.utils';

interface AccommodationsCtaArgs {
  eventHandle: string;
  displayName?: AccommodationCombined['displayName'];
  type: AccommodationCombinedType;
  isFavorite?: AccommodationCombined['isFavorite'];
  pricePerNight?: AccommodationCombined['pricePerNight'];
  strikeoutPricePerNight?: AccommodationCombined['strikeoutPricePerNight'];
  zhId: AccommodationCombined['zhId'];
  url: AccommodationCombined['url'];
  id: AccommodationCombined['id'];
  interestedGuestCount: AccommodationCombined['interestedGuestCount'];
  clickedFrom?: 'tileFavorite' | 'dialog';
  setRedirectLink?: (redirectLink: string) => void;
}

export const useAccommodationsCta = (props: AccommodationsCtaArgs) => {
  const { eventHandle, displayName, type, pricePerNight, isFavorite, strikeoutPricePerNight, id, interestedGuestCount, clickedFrom } = props;

  const { roomblock, buildPath } = useHotelBookingRoutePaths();
  const [incrementInterestedGuestCount] = useIncrementInterestedGuestCountMutation();
  const { setShowNoLeadDialog, enabledAdminGuestSiteBannerDialog } = useGuestSiteState();
  const { accommodationPlace } = useAccommodationPlaceRoutePaths();

  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');

  const { ctaTitle } = useMemo(() => {
    const buttonCta = {
      [AccommodationCombinedType.Custom]: accTrans.selectCTACustom(),
      [AccommodationCombinedType.Hotel]: accTrans.selectCTAHotel(),
      [AccommodationCombinedType.Roomblock]: accTrans.selectCTARoomBlock()
    };
    return {
      ctaTitle: buttonCta[type]
    };
  }, [type, accTrans]);

  const telemetry = useCustomPageTelemetry();
  const telemetryHotelInfo = {
    hotelName: displayName || '',
    isHotelPreferred: isFavorite || false,
    hotelPrice: pricePerNight || undefined,
    hotelStrikeThroughPrice: strikeoutPricePerNight || undefined,
    clickedFrom,
    accommodationsType: type
  };

  const ctaUrl = useMemo(
    () =>
      withWindow(
        global =>
          type === AccommodationCombinedType.Roomblock ? `${global.location.origin}${buildPath(eventHandle, roomblock, id)}` : accommodationPlace.goToPath(eventHandle, id),
        accommodationPlace.goToPath(eventHandle, id)
      ),
    [accommodationPlace, buildPath, eventHandle, id, roomblock, type]
  );

  const handleOnSelectAccommodation = useEventCallback(() => {
    if (type === AccommodationCombinedType.Roomblock) {
      handleOnSelectRoomBlock();
      return;
    }
    telemetry.accommodationsLinkInteracted(accommodationPlace.goToPath(eventHandle, id), telemetryHotelInfo);
  });

  const handleOnSelectRoomBlock = useEventCallback(() => {
    if (enabledAdminGuestSiteBannerDialog) {
      telemetry.leadGenDialogPromptedClicked();
      setShowNoLeadDialog(true);
    } else {
      incrementInterestedGuestCount({
        variables: { reservedRoomBlockId: id }
      });
      const telemetryHotelInfoWithInterestCountIfAvailable = {
        ...telemetryHotelInfo,
        displayedInterestedGuestCount: interestedGuestCount || 0
      };
      telemetry.accommodationsLinkInteracted(buildPath(eventHandle, roomblock, id), telemetryHotelInfoWithInterestCountIfAvailable);
    }
  });

  return {
    ctaTitle,
    ctaUrl,
    handleOnSelectAccommodation
  };
};
