import { BOX_SHADOW_60_02 } from '@apps/card/boxShadow';
import { animationTransition } from '@shared/utils/animationTransition';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { Box, Flex, styled } from '@withjoy/joykit';
import React, { useEffect, useRef, useState } from 'react';
import { useGalleryGridStyle } from '../../../../useGalleryGridStyle';
import { StationeryTemplate } from '../../../../DesignsGallery.types';
import { BOX_SHADOW_8 } from '@apps/card/boxShadow';
import { AspectRatioImage } from '../../../../../AspectRatioImage';
import Slider from 'react-slick';
import { CardCarouselSlide, filterCardCarouselValues } from '../../useCardCarouselValues';
import { CarouselSlide } from './CarouselSlide';

const BOX_SHADOW_DEPTH = `
-0.5px 0.5px 0px 0px #e5e7eb,
-0.85px 1px 0px 0px #e5e7eb`;
const HARD_SHADOW = '-24px 30px 8px -16px rgba(0, 0, 0, 0.3)';
const SOFT_SHADOW = '-60px 80px 24px -22px rgba(0,0,0,0.2)';

const TRANSFORMED_CARD_SCALE = '1.65';
const TRANSFORMED_CARD_ROTATION_X = '55deg';
const TRANSFORMED_CARD_ROTATION_Y = '9deg';
const TRANSFORMED_CARD_ROTATION_Z = '-34deg';
const TRANSFORMED_CARD_TRANSLATION_X = '15%';
const TRANSFORMED_CARD_TRANSLATION_Y = '-10%';
const TRANSFORMED_CARD_SKEW_X = '-5deg';
const TRANSFORMED_CARD_SKEW_Y = '0deg';

const SliderContainer = styled(Flex)`
  width: round(down, 100%, 1px);

  > .slick-slider {
    width: 100%;
  }

  .slick-list {
    height: 100%;
  }

  .slick-track {
    height: 100%;
  }

  .slick-slide {
    height: 100%;
    overflow: hidden;
  }

  .slick-slide > div {
    height: 100%;
  }
`;

const SLIDER_SETTINGS = {
  dots: false,
  draggable: false,
  arrows: false,
  autoplay: false,
  pauseOnHover: false,
  speed: 750,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  autoplaySpeed: 1500,
  waitForAnimate: false
};

interface CardTileCarouselProps {
  data: StationeryTemplate;
  slides: CardCarouselSlide[];
}

export const CardTileCarousel: React.FC<React.PropsWithChildren<CardTileCarouselProps>> = ({ data, slides, children }) => {
  const carouselRef = useRef<Slider>(null);

  const {
    assetPreviewAspectRatioMobile,
    assetPreviewFrameBackground,
    assetPreviewFrameGroupHoverBackground,
    assetPreviewFramePaddingDesktop,
    assetPreviewFramePaddingMobile,
    aspectRatioFrameBoxShadow
  } = useGalleryGridStyle();
  const isMobile = useIsMobileScreen();
  const aspectRatio = isMobile && typeof assetPreviewAspectRatioMobile === 'string' ? assetPreviewAspectRatioMobile : '9 / 10';
  const assetPreviewFramePadding = isMobile ? assetPreviewFramePaddingMobile : assetPreviewFramePaddingDesktop;

  const { thumbnailUrl, digitalDesignThumbnailUrl } = data;
  const carouselSlides = filterCardCarouselValues(slides, data.category, 'wedding');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const startTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (startTimeoutRef.current) {
        clearTimeout(startTimeoutRef.current);
      }
    };
  }, []);

  const handleEnter = () => {
    startTimeoutRef.current = setTimeout(() => {
      carouselRef.current?.slickGoTo(1);
      carouselRef.current?.slickPlay();
      setIsActive(true);
    }, 750);
  };

  const handleLeave = () => {
    if (startTimeoutRef.current) {
      clearTimeout(startTimeoutRef.current);
    }

    carouselRef.current?.slickPause();
    carouselRef.current?.slickGoTo(0);
    setIsActive(false);
  };

  const requestPause = () => {
    if (!isMobile) {
      carouselRef.current?.slickPause();
    }
  };

  const requestPlay = () => {
    if (!isMobile) {
      carouselRef.current?.slickPlay();
    }
  };

  return (
    <Box position="relative" width="100%" maxWidth="100%" overflow="hidden" onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
      <SliderContainer aspectRatio={aspectRatio}>
        <Slider
          afterChange={current => {
            setCurrentSlide(current);
          }}
          onSwipe={() => {
            setIsActive(true);
          }}
          {...SLIDER_SETTINGS}
          ref={carouselRef}
        >
          <Flex
            transition={animationTransition('background')}
            background={assetPreviewFrameBackground}
            _groupHover={{
              background: assetPreviewFrameGroupHoverBackground
            }}
            flexDirection="column"
            width="100%"
            height="100%"
            paddingX={assetPreviewFramePadding ?? 5}
            paddingY={assetPreviewFramePaddingDesktop ?? 5}
          >
            <Flex flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
              <AspectRatioImage
                src={(thumbnailUrl || digitalDesignThumbnailUrl) ?? ''}
                borderRadius={1}
                boxShadow={aspectRatioFrameBoxShadow ?? BOX_SHADOW_8}
                transition={animationTransition('box-shadow')}
                _groupHover={{
                  boxShadow: aspectRatioFrameBoxShadow ?? `${BOX_SHADOW_8},${BOX_SHADOW_60_02}`
                }}
              />
            </Flex>
          </Flex>
          <Flex
            transition={animationTransition('background')}
            background={assetPreviewFrameBackground}
            _groupHover={{
              background: assetPreviewFrameGroupHoverBackground
            }}
            flexDirection="column"
            width="100%"
            height="100%"
            paddingX={assetPreviewFramePadding ?? 5}
            paddingY={assetPreviewFramePaddingDesktop ?? 5}
          >
            <Flex flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%" style={{ perspective: '900px', perspectiveOrigin: 'center' }}>
              <AspectRatioImage
                transform={`scale(${TRANSFORMED_CARD_SCALE}) rotateX(${TRANSFORMED_CARD_ROTATION_X}) rotateY(${TRANSFORMED_CARD_ROTATION_Y}) rotateZ(${TRANSFORMED_CARD_ROTATION_Z}) translate(${TRANSFORMED_CARD_TRANSLATION_X}, ${TRANSFORMED_CARD_TRANSLATION_Y}) skewX(${TRANSFORMED_CARD_SKEW_X}) skewY(${TRANSFORMED_CARD_SKEW_Y})`}
                src={(thumbnailUrl || digitalDesignThumbnailUrl) ?? ''}
                boxShadow={`${BOX_SHADOW_DEPTH}, ${HARD_SHADOW}, ${SOFT_SHADOW}`}
                transition={animationTransition('box-shadow')}
              />
            </Flex>
          </Flex>
          {carouselSlides.map((slide, index) => {
            // Position is offset by number of proceeding hardcoded slides.
            const position = index + 2;
            return (
              <CarouselSlide
                onRequestPause={requestPause}
                onRequestPlay={requestPlay}
                isCarouselActive={isActive}
                isActiveSlide={currentSlide === position}
                key={index}
                slide={slide}
              />
            );
          })}
        </Slider>
      </SliderContainer>
      {children}
    </Box>
  );
};
