import React, { useMemo } from 'react';
import { AssetContainer, CardPreviewPaneProps, GalleryAsset, GenericPreviewPane } from '../GenericPreviewPane';
import { StationeryDraftFormat } from '@graphql/generated';
import { Shadow } from '@apps/card/routes/CardCustomizer/steps/CardDesign/CardPagePreview';
import { Page } from '@apps/card/routes/CardCustomizer/components/Page';
import { PageScaler } from '@apps/card/routes/CardCustomizer/components/PageScaler';
import { FitScaler } from '@apps/card/routes/CardCustomizer/components/FitScaler';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { CardPreviewFavoriteButton } from '../CardCustomizationContent/CardPreviewFavoriteButton';
import { useGalleryGridStyle } from '../../../DesignsGallery/useGalleryGridStyle';
import { useCardPreviewPaneAssets } from '../useCardPreviewPaneAssets';

export const CardPreviewPane = (props: CardPreviewPaneProps) => {
  const isMobile = useIsMobileScreen();
  const { cardFront, cardShape, thumbnailUrl, eventId, templateId, isFavorite, themeId, templateCategory } = props;
  const { aspectRatioFrameBoxShadow } = useGalleryGridStyle();
  const extraAssets = useCardPreviewPaneAssets('wedding', templateCategory, 'joy_digital');

  const assets: Array<GalleryAsset> = useMemo(() => {
    return [
      {
        render: () => {
          const content = (
            <Shadow shadow={aspectRatioFrameBoxShadow as string} shape={cardShape}>
              <Page width={5} height={7} page={cardFront} shape={cardShape} format={StationeryDraftFormat.digital} />
            </Shadow>
          );

          return (
            <AssetContainer
              key="cardFront"
              label="Front of the Card"
              enforceAspectRatio={!isMobile}
              badge={<CardPreviewFavoriteButton themeId={themeId} templateCategory={templateCategory} eventId={eventId || ''} templateId={templateId} isFavorite={isFavorite} />}
            >
              {isMobile ? <PageScaler>{content}</PageScaler> : <FitScaler>{content}</FitScaler>}
            </AssetContainer>
          );
        },
        thumbnailUrl: thumbnailUrl
      },
      ...extraAssets
    ];
  }, [thumbnailUrl, extraAssets, aspectRatioFrameBoxShadow, cardShape, cardFront, isMobile, themeId, templateCategory, eventId, templateId, isFavorite]);

  return <GenericPreviewPane {...props} assets={assets} />;
};
