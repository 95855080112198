import React, { useMemo, useState } from 'react';
import { useTranslation } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { Category, PointsOnMapFragment, useIncrementInterestedGuestCountMutation } from '@graphql/generated';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { Flex, ButtonV2 } from '@withjoy/joykit';
import { useLayout } from '@apps/guest/packages/layout-engine/layouts/LayoutProvider';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { useHotelBookingRoutePaths } from '@apps/guest/routes/HotelBooking/HotelBooking.routes';
import { useCustomPageTelemetry } from '../../../../Custom.telemetry';
import {
  AccommodationTileDescription,
  AccommodationTileDiscount,
  AccommodationTileSchedule,
  AccommodationTileImage,
  AccommodationTileSubtitle,
  AccommodationTileTitle,
  AccommodationTileAddress,
  AccommodationTilePhone,
  AccommodationTileEyebrow
} from './components';
import { AccommodationCombined, AccommodationCombinedType } from './Accommodation.types';
import { useAccommodationPlaceRoutePaths } from '@apps/guest/routes/HotelBooking/routes/AccommodationPlacePartner/AccommodationPlacePartner.utils';
import { useFeatureValue } from '@shared/core/featureFlags';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { button2ToButtonsOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { pxToRem } from '@withjoy/joykit/theme';
import { RedirectWithSafeLink } from '@apps/guest/components/RedirectWithSafeLink';

interface AccommodationsTileProps extends AccommodationCombined {
  locationInfo?: PointsOnMapFragment;
  eventPhoto: string;
  eventHandle: string;
  isFirst: boolean;
  isLast: boolean;
  showDefaultData?: boolean;
  setLocationBeingHovered?: (id: string | undefined) => void;
}

const AccommodationsTile: React.FC<AccommodationsTileProps> = props => {
  const { layout } = useLayout();
  const {
    description,
    displayName,
    photo,
    eventPhoto,
    starRating,
    isFavorite,
    cutoffDate,
    strikeoutPricePerNight,
    pricePerNight,
    checkInDate,
    checkOutDate,
    phone,
    id,
    address,
    isFirst,
    isLast,
    eventHandle,
    type,
    showDefaultData,
    setLocationBeingHovered,
    interestedGuestCount,
    city,
    state,
    postalCode,
    country,
    customAddress
  } = props;
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');
  const { roomblock, buildPath } = useHotelBookingRoutePaths();
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const isMobileOrTablet = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'md' }));
  const { setShowNoLeadDialog, enabledAdminGuestSiteBannerDialog } = useGuestSiteState();
  const [incrementInterestedGuestCount] = useIncrementInterestedGuestCountMutation();
  const { accommodationPlace } = useAccommodationPlaceRoutePaths();
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';
  const [safeLink, setSafeLink] = useState<string | undefined>();

  const { scaleFontSize } = useScaleGuestSiteFontSize();

  const { ctaTile } = useMemo(() => {
    const buttonCta = {
      [AccommodationCombinedType.Custom]: accTrans.selectCTACustom(),
      [AccommodationCombinedType.Hotel]: accTrans.selectCTAHotel(),
      [AccommodationCombinedType.Roomblock]: accTrans.selectCTARoomBlock()
    };
    return {
      ctaTile: buttonCta[type]
    };
  }, [type, accTrans]);
  const telemetry = useCustomPageTelemetry();
  const telemetryHotelInfo = {
    hotelName: displayName || '',
    isHotelPreferred: isFavorite || false,
    hotelPrice: pricePerNight || undefined,
    hotelStrikeThroughPrice: strikeoutPricePerNight || undefined,
    accommodationsType: type
  };

  const isBrannan = layout === 'brannan';

  const handleOnSelectAccommodation = useEventCallback(() => {
    if (type === AccommodationCombinedType.Roomblock) {
      handleOnSelectRoomBlock();
      return;
    }
    telemetry.accommodationsLinkInteracted(accommodationPlace.goToPath(eventHandle, id), telemetryHotelInfo);
    withWindow(global => global.open(`${accommodationPlace.goToPath(eventHandle, id)}`, '_blank'));
  });

  const handleOnSelectRoomBlock = useEventCallback(() => {
    if (enabledAdminGuestSiteBannerDialog) {
      telemetry.leadGenDialogPromptedClicked();
      setShowNoLeadDialog(true);
    } else {
      incrementInterestedGuestCount({
        variables: { reservedRoomBlockId: id }
      });

      const telemetryHotelInfoWithInterestCountIfAvailable = {
        ...telemetryHotelInfo,
        displayedInterestedGuestCount: interestedGuestCount || 0
      };

      telemetry.accommodationsLinkInteracted(buildPath(eventHandle, roomblock, id), telemetryHotelInfoWithInterestCountIfAvailable);

      withWindow(global => global.open(`${global.location.origin}${buildPath(eventHandle, roomblock, id)}`, '_blank'));
    }
  });

  return (
    <Flex paddingTop={isBrannan ? { _: 8, sm2: 0 } : isFirst ? 0 : 8} paddingBottom={isBrannan && isLast ? (isMobile ? 8 : 0) : 8} width={'100%'} justifyContent="center">
      <Flex maxWidth={isBrannan ? 520 : 500} width={'100%'}>
        <Flex width={'100%'} display={'column'}>
          <AccommodationTileImage
            id={id}
            type={type}
            telemetryHotelInfo={telemetryHotelInfo}
            eventHandle={eventHandle}
            photoUrl={photo?.url}
            showDefaultData={showDefaultData}
            interestedGuestCount={interestedGuestCount}
          />
          <Flex flexDirection={'column'} width={'100%'} paddingTop={6} paddingBottom={7}>
            {type === AccommodationCombinedType.Roomblock && <AccommodationTileEyebrow displayName={accTrans.roomBlockEyebrow()} />}
            <AccommodationTileTitle displayName={displayName} />
            <AccommodationTileSubtitle
              starRating={starRating}
              venueLatitude={props.locationInfo?.latitude}
              venueLongitude={props.locationInfo?.longitude}
              accommodationLatitude={props.latitude}
              accommodationLongitude={props.longitude}
            />
          </Flex>
          <Flex flexDirection={'column'} rowGap={6}>
            <AccommodationTileDescription eventPhoto={eventPhoto} description={description} isFavorite={!!isFavorite} />
            <AccommodationTileSchedule type={type} cutoffDate={cutoffDate} />
            <AccommodationTileAddress type={type} customAddress={customAddress} address={address} city={city} state={state} postalCode={postalCode} country={country} />
            <AccommodationTilePhone type={type} phone={phone} />
            <AccommodationTileDiscount
              type={type}
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              pricePerNight={pricePerNight}
              strikeoutPricePerNight={strikeoutPricePerNight}
            />
            <GuestSiteTypographyOverride override={button2ToButtonsOverride} defaultFontFamily="Inter UI">
              <ButtonV2
                width="100%"
                intent="neutral"
                shape="rounded"
                fontWeight={600}
                fontSize={scaleFontSize(pxToRem(16), Category.BUTTONS)}
                {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                onMouseEnter={() => !isMobileOrTablet && setLocationBeingHovered?.(id)}
                onMouseLeave={() => !isMobileOrTablet && setLocationBeingHovered?.(undefined)}
                onClick={handleOnSelectAccommodation}
              >
                {ctaTile}
              </ButtonV2>
            </GuestSiteTypographyOverride>
          </Flex>
        </Flex>
      </Flex>
      <RedirectWithSafeLink
        href={safeLink}
        skipTelemetry
        onClose={() => {
          setSafeLink(undefined);
        }}
      />
    </Flex>
  );
};

AccommodationsTile.displayName = 'AccommodationsTile';

export { AccommodationsTile };
