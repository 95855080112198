import { Flex, LinkV2, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { Filters } from './Filters';
import { FilterFacet } from './Filter.types';
import { SearchBar } from '../Search/SearchBar';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { useApplySearchFiltersToTemplates } from '../../useApplySearchFiltersToTemplates';
import { useFeatureValue } from '@shared/core/featureFlags';

type SearchAndFiltersContainerProps = {
  showFilters: boolean;
  filterFacets: FilterFacet[];
};

const NoSearchResultsText = ({ onClick }: { onClick?: () => void }) => {
  return (
    <TextV2 paddingLeft={['1.5rem', '2.5rem']} marginY="auto">
      No search results.{' '}
      <span>
        <LinkV2 onClick={onClick} textDecoration="none">
          Clear
        </LinkV2>
      </span>
    </TextV2>
  );
};

export const SearchAndFiltersContainer = (props: SearchAndFiltersContainerProps) => {
  const { showFilters, filterFacets } = props;
  const isMobile = useIsMobileOrTablet();

  const { value } = useFeatureValue('printGalleryEnableSearch');
  const searchEnabled = value === 'on';

  const { useSearchResults, clearSearch } = useApplySearchFiltersToTemplates();
  const searchResults = useSearchResults();
  const { searchError } = searchResults;

  return (
    <>
      <Flex>
        {showFilters && <Filters facets={filterFacets} />}
        {searchEnabled && <SearchBar showFilters={showFilters} />}
        {!isMobile && searchError && <NoSearchResultsText onClick={clearSearch} />}
      </Flex>
      {isMobile && searchError && <NoSearchResultsText onClick={clearSearch} />}
    </>
  );
};
