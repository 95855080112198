import React, { useMemo } from 'react';
import { Box, Divider } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { PointsOnMapFragment } from '@graphql/generated';
import { useLayout } from '@apps/guest/packages/layout-engine/layouts/LayoutProvider';
import { AccommodationsTile } from './AccommodationsTile';
import { AccommodationCombined } from './Accommodation.types';
import { sortByFavorite, sortByOrderList } from '@shared/utils/array';

interface HotelTileListProps {
  destination?: PointsOnMapFragment;
  hotelTileListData: AccommodationCombined[];
  eventPhoto: string;
  eventHandle: string;
  showDefaultData?: boolean;
  blockOrdering?: string[] | null;
  setLocationBeingHovered?: (id: string | undefined) => void;
}

const Accommodations: React.FC<HotelTileListProps> = ({ destination, hotelTileListData, eventPhoto, eventHandle, blockOrdering, showDefaultData, setLocationBeingHovered }) => {
  const { layout } = useLayout();
  const isBrannan = layout === 'brannan';
  const sortedHotelList = useMemo(() => sortByFavorite(sortByOrderList(hotelTileListData, blockOrdering ?? [])), [blockOrdering, hotelTileListData]);
  return (
    <>
      {sortedHotelList.map((hotel, idx) => {
        return (
          <Box key={hotel.id} id={hotel.id} width="100%">
            <AccommodationsTile
              {...hotel}
              isFirst={idx === 0}
              isLast={idx === hotelTileListData.length - 1}
              locationInfo={destination}
              eventPhoto={eventPhoto}
              eventHandle={eventHandle}
              showDefaultData={showDefaultData}
              setLocationBeingHovered={setLocationBeingHovered}
            />
            {idx < hotelTileListData.length - 1 && (
              <Divider
                maxWidth={isBrannan ? { _: pxToRem(520), sm2: '100%' } : {}}
                margin={isBrannan ? { _: 'auto', sm2: 'inherit' } : {}}
                marginBottom={isBrannan ? { _: 0, sm2: 8 } : 0}
              />
            )}
          </Box>
        );
      })}
    </>
  );
};

Accommodations.displayName = 'Accommodations';

export { Accommodations };
