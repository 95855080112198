import React, { useEffect, useState } from 'react';
import { ThemeJson } from '@apps/card/routes/CardCustomizer';
import { animationTransition } from '@shared/utils/animationTransition';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { Box, Flex, BoxProps } from '@withjoy/joykit';
import { PRODUCT_PDP_ROOT_ID } from '../constants';
import { CardDieCut } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useGalleryGridStyle } from '../../DesignsGallery/useGalleryGridStyle';

export interface GalleryAsset {
  render: () => JSX.Element;
  thumbnailUrl: string;
}

export interface AssetContainerProps {
  label: string;
  enforceAspectRatio?: boolean;
  backgroundImageUrl?: string;
  labelBackgroundColor?: BoxProps['color'];
  badge?: JSX.Element;
}

export const AssetContainer: React.FC<AssetContainerProps> = props => {
  const { assetPreviewFrameBackground } = useGalleryGridStyle();

  const { backgroundImageUrl, labelBackgroundColor = 'white', enforceAspectRatio, children, label, badge } = props;
  return (
    <Box
      backgroundImage={backgroundImageUrl ? `url('${backgroundImageUrl}')` : undefined}
      backgroundSize="cover"
      backgroundPosition="center"
      position="relative"
      aspectRatio={enforceAspectRatio ? '1' : undefined}
      backgroundColor={assetPreviewFrameBackground as BoxProps['color']}
      paddingTop={64}
      paddingBottom={[16, 16, 64]}
      paddingX={[40, null, 16]}
      marginX={[-24, null, 0]}
      width={['calc(100% + 48px)', null, '100%']}
      display="flex"
      justifyContent="center"
      overflow="hidden"
    >
      <Box
        borderRadius={5}
        backgroundColor={labelBackgroundColor}
        position="absolute"
        top={5}
        left={5}
        display={{ _: 'none', sm2: 'block' }}
        paddingX={5}
        paddingY={2}
        typographyVariant="label3"
      >
        {label}
      </Box>
      {badge && (
        <Box position="absolute" top={5} right={[8, null, 5]} zIndex={10}>
          {badge}
        </Box>
      )}
      <Box width="100%">{children}</Box>
    </Box>
  );
};

export const ScrollableScreenCover = () => (
  <Box
    className="cover"
    __css={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgb(0, 0, 0, 0)',
      zIndex: 5
    }}
  />
);

const MobilePreview = ({ cardFront, assets }: CardPreviewPaneProps & { assets: GalleryAsset[] }) => {
  const [selectedAssetIndex, setSelectedAssetIndex] = useState<number>(0);

  useEffect(() => {
    // When user customizes the card, bring focus back to the card preview
    setSelectedAssetIndex(0);
  }, [cardFront]);

  const spotlightAsset = assets[selectedAssetIndex] || assets[0];

  return (
    <Flex width="100%" flexDirection="column" rowGap={6}>
      <Box position="relative" width="100%" backgroundColor="mono1">
        <ScrollableScreenCover />
        {spotlightAsset.render()}
      </Box>
      <Flex columnGap={1}>
        {assets.map((asset, idx) => (
          <Box
            _after={{
              position: 'absolute',
              content: '" "',
              width: '100%',
              bottom: -12,
              backgroundColor: 'mono10',
              borderRadius: 2,
              height: '2px',
              transition: animationTransition('opacity'),
              opacity: selectedAssetIndex === idx ? 1 : 0
            }}
            display="inline-flex"
            justifyContent={'center'}
            size={9}
            key={idx}
            backgroundColor="mono2"
            padding={1}
            position="relative"
          >
            <Box
              aspectRatio={'1'}
              backgroundImage={`url('${asset.thumbnailUrl}')`}
              backgroundSize={'contain'}
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              cursor="pointer"
              onClick={() => setSelectedAssetIndex(idx)}
            />
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

const DesktopPreview = ({ cardShape, assets }: CardPreviewPaneProps & { assets: GalleryAsset[] }) => {
  useEffect(() => {
    // Scroll to the top of the dialog when user changes the card shape
    const dialogRoot = document.getElementById(PRODUCT_PDP_ROOT_ID);
    if (dialogRoot) {
      dialogRoot.scrollTop = 0;
    }
  }, [cardShape]);

  return (
    <Flex width="100%" flexDirection="column">
      {assets.map(asset => asset.render())}
    </Flex>
  );
};

export interface CardPreviewPaneProps {
  templateCategory: StationeryTemplateCategoryEnum;
  thumbnailUrl: string;
  cardFront: ThemeJson['card']['front'];
  cardShape: CardDieCut;
  eventId?: string;
  templateId: string;
  isFavorite: boolean;
  themeId: string;
}

export const GenericPreviewPane = (props: CardPreviewPaneProps & { assets: GalleryAsset[] }) => {
  const isMobile = useIsMobileScreen();
  const { assets } = props;

  return <Box width="100%">{isMobile ? <MobilePreview {...props} assets={assets} /> : <DesktopPreview {...props} assets={assets} />}</Box>;
};
