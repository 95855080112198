import { DEFAULT_DIGITAL_PRICE_CONFIG, DigitalPriceConfig } from '@apps/card/config';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useDigitalCardCustomization } from '../../CardCustomizer/useCardCustomization';

type DigitalDeliveryMethod = DigitalPriceConfig['delivery'][string];
const isValidDeliveryMethod = (value: unknown): value is DigitalDeliveryMethod => {
  if (!value) {
    return false;
  }

  const asDelivery = value as DigitalDeliveryMethod;

  // Must have valid price
  if (!Object.hasOwn(value, 'price') || typeof asDelivery.price !== 'number') {
    return false;
  }

  // Must have valid quantity
  if (!Object.hasOwn(value, 'quantity') || typeof asDelivery.quantity !== 'number') {
    return false;
  }

  // Must have valid upcharge
  if (!Object.hasOwn(value, 'diamondUpcharge') || typeof asDelivery.diamondUpcharge !== 'number') {
    return false;
  }

  // Must have valid upcharge
  if (!Object.hasOwn(value, 'premiumUpcharge') || typeof asDelivery.premiumUpcharge !== 'number') {
    return false;
  }

  return true;
};

const isValidDigitalPriceConfig = (payload: unknown): payload is DigitalPriceConfig => {
  if (!payload) {
    return false;
  }

  if (typeof payload !== 'object') {
    return false;
  }

  const priceConfig = payload as DigitalPriceConfig;

  // Must have a delivery method list with at least 1 option
  if (!Object.hasOwn(payload, 'delivery') || typeof priceConfig.delivery !== 'object') {
    return false;
  }

  const deliveryMethods = Object.keys(priceConfig.delivery);
  if (deliveryMethods.length < 1) {
    return false;
  }

  // All delivery methods must be valid
  if (deliveryMethods.some(key => !isValidDeliveryMethod(priceConfig.delivery[key]))) {
    return false;
  }

  return true;
};

type PremiumDigitalPriceConfig = { digitalPriceConfig: DigitalPriceConfig; priceEachInMinorUnits: number };

export const usePremiumDigitalPriceConfig = (): PremiumDigitalPriceConfig => {
  const { value, payload } = useFeatureValue('printPremiumDigitalPricing');

  const digitalPriceConfig = value === 'on' && isValidDigitalPriceConfig(payload) ? (payload as DigitalPriceConfig) : DEFAULT_DIGITAL_PRICE_CONFIG;

  const defaultConfigOption = getDefaultPricingConfigOption(digitalPriceConfig);

  return { digitalPriceConfig, priceEachInMinorUnits: Math.floor(defaultConfigOption.price / defaultConfigOption.quantity) };
};

export const usePremiumDigitalPriceConfigOption = () => {
  const [draft] = useDigitalCardCustomization();
  const { digitalPriceConfig } = usePremiumDigitalPriceConfig();

  const quantity = draft.quantity || '';

  const defaultConfigOption = getDefaultPricingConfigOption(digitalPriceConfig);

  return digitalPriceConfig.delivery[quantity] || defaultConfigOption;
};

const getDefaultPricingConfigOption = (config: DigitalPriceConfig) => {
  const defaultQuantityKey = Object.keys(config.delivery).sort((a, b) => parseInt(a) - parseInt(b))[0];

  return config.delivery[defaultQuantityKey];
};
