import React, { useMemo } from 'react';
import { AssetContainer, CardPreviewPaneProps, GalleryAsset, GenericPreviewPane } from '../GenericPreviewPane';
import { StationeryDraftFormat, StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { Shadow } from '@apps/card/routes/CardCustomizer/steps/CardDesign/CardPagePreview';
import { Page } from '@apps/card/routes/CardCustomizer/components/Page';
import { PageScaler } from '@apps/card/routes/CardCustomizer/components/PageScaler';
import { FitScaler } from '@apps/card/routes/CardCustomizer/components/FitScaler';
import { CardPreviewFavoriteButton } from './CardPreviewFavoriteButton';
import { useGalleryGridStyle } from '../../../DesignsGallery/useGalleryGridStyle';
import { useFeatureValue } from '@shared/core/featureFlags';
import { Box } from '@withjoy/joykit';
import { useCardPreviewPaneAssets } from '../useCardPreviewPaneAssets';

export const getCardBackPreviewImage = (category: StationeryTemplateCategoryEnum) => {
  switch (category) {
    case StationeryTemplateCategoryEnum.thankYou: {
      return 'https://withjoy.com/assets/public/back-variety-preview-thankyoucard-2.jpg';
    }
    case StationeryTemplateCategoryEnum.holiday: {
      return 'https://withjoy.com/media/print/back-variety-preview-holiday.jpg';
    }
    default: {
      return 'https://withjoy.com/assets/public/back-variety-preview-5.jpg';
    }
  }
};

export const getEnvelopePreviewImage = (category: StationeryTemplateCategoryEnum) => {
  switch (category) {
    case StationeryTemplateCategoryEnum.thankYou: {
      return 'https://withjoy.com/assets/public/envelope-variety-preview-thankyoucard-2.jpg';
    }
    case StationeryTemplateCategoryEnum.holiday: {
      return 'https://withjoy.com/media/print/envelope-variety-preview-holiday.jpg';
    }
    default: {
      return 'https://withjoy.com/assets/public/envelope-variety-preview-4.jpg';
    }
  }
};

export const getEnvelopeAddressPreviewImage = (category: StationeryTemplateCategoryEnum) => {
  switch (category) {
    case StationeryTemplateCategoryEnum.thankYou: {
      return 'https://withjoy.com/assets/public/envelope-address-preview-with-recipient-thankyoucard-2.jpg';
    }
    case StationeryTemplateCategoryEnum.holiday: {
      return 'https://withjoy.com/media/print/envelope-address-preview-with-recipient.jpg';
    }
    default: {
      return 'https://withjoy.com/assets/public/envelope-address-preview-with-recipient-3.jpg';
    }
  }
};

const extractVideoTemplateFromAmplitude = (themeId: string, videosMap: Record<string, { videoUrl: string; thumbnailUrl: string }> | undefined) => {
  const maybeVideoTemplate = videosMap && themeId in videosMap ? videosMap[themeId] : undefined;
  return !!maybeVideoTemplate?.thumbnailUrl && !!maybeVideoTemplate?.videoUrl ? maybeVideoTemplate : undefined;
};

export const CardPreviewPane = (props: CardPreviewPaneProps) => {
  const isMobile = useIsMobileScreen();
  const { templateCategory, thumbnailUrl, cardFront, cardShape, eventId, templateId, themeId, isFavorite } = props;
  const { aspectRatioFrameBoxShadow } = useGalleryGridStyle();

  const { value: isPopularDesignPdpVideoExperimentEnabled, payload: videoExperimentPayload } = useFeatureValue('printPopularDesignPdpVideoExperiment');
  const videosMap = videoExperimentPayload as Record<string, { videoUrl: string; thumbnailUrl: string }> | undefined;
  const videoDataForTemplate = isPopularDesignPdpVideoExperimentEnabled && extractVideoTemplateFromAmplitude(themeId, videosMap);
  const extraAssets = useCardPreviewPaneAssets('wedding', templateCategory, 'taylor');

  const assets: Array<GalleryAsset> = useMemo(() => {
    return [
      {
        render: () => {
          const content = (
            <Shadow shape={cardShape} shadow={aspectRatioFrameBoxShadow as string}>
              <Page width={5} height={7} page={cardFront} shape={cardShape} format={StationeryDraftFormat.print} />
            </Shadow>
          );

          return (
            <AssetContainer
              key="cardFront"
              label="Front of the Card"
              enforceAspectRatio={!isMobile}
              badge={<CardPreviewFavoriteButton themeId={themeId} templateCategory={templateCategory} eventId={eventId || ''} templateId={templateId} isFavorite={isFavorite} />}
            >
              {isMobile ? <PageScaler>{content}</PageScaler> : <FitScaler>{content}</FitScaler>}
            </AssetContainer>
          );
        },
        thumbnailUrl: thumbnailUrl
      },
      ...(videoDataForTemplate
        ? [
            {
              render: () => (
                <AssetContainer label="" key="video" enforceAspectRatio>
                  <Box
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    position="absolute"
                    backgroundPosition="center"
                    backgroundSize="cover"
                    backgroundRepeat="no-repeat"
                    backgroundImage={`url('${videoDataForTemplate.thumbnailUrl}')`}
                  >
                    <Box
                      as="iframe"
                      border="none"
                      outline="none"
                      width="100%"
                      height="100%"
                      position="absolute"
                      top={0}
                      left={0}
                      title="d"
                      loading="lazy"
                      src={`${videoDataForTemplate.videoUrl}&autoplay=1&controls=0&loop=1&mute=1&background=1`}
                    />
                  </Box>
                </AssetContainer>
              ),
              thumbnailUrl: videoDataForTemplate.thumbnailUrl
            }
          ]
        : []),
      ...extraAssets
    ];
  }, [thumbnailUrl, videoDataForTemplate, extraAssets, templateCategory, cardShape, aspectRatioFrameBoxShadow, cardFront, isMobile, themeId, eventId, templateId, isFavorite]);

  return <GenericPreviewPane {...props} assets={assets} />;
};
