import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { ecardRoutePaths } from '@apps/ecard/Ecard.route.paths';
import { getThemeInfoByThemeId } from '@apps/saveTheDate/SaveTheDate.utils';
import { EcardType, StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useRouterHelper } from '@shared/core';
import { useFeatureFlagsContext } from '@shared/core/featureFlags';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { withWindow } from '@shared/utils/withWindow';
import { DigitalDesignTemplateCategory, Format } from '../DesignsGallery/DesignsGallery.types';
import { useCallback } from 'react';
import { useCardsRouterContext } from '@apps/card/Card.routes';

interface UseCustomizeDigitalCardArgs {
  stationeryTemplate: StationeryTemplateCategoryEnum;
  supportedFormats: Format;
}

const DIGITAL_DESIGN_TEMPLATE_CATEGORY_TO_ECARD_TYPE: Record<DigitalDesignTemplateCategory, EcardType> = {
  saveTheDate: EcardType.savethedate,
  invitation: EcardType.invitation
};

const STATIONERY_TEMPLATE_TO_ECARD_TYPE: Record<StationeryTemplateCategoryEnum, EcardType | undefined> = {
  saveTheDate: EcardType.savethedate,
  invitation: EcardType.invitation,
  thankYou: undefined,
  holiday: undefined,
  enclosure: undefined
};

export const useConstructEcardEditorRedirectUrl = () => {
  const { isPublicShopRoute } = useCardsRouterContext();
  const { buildPath } = useRouterHelper();
  const { formatToUrl } = useFeatureFlagsContext();
  return useCallback(
    (ecardType: DigitalDesignTemplateCategory, themeId: string) => {
      let ecardNestedPath: string;
      const mappedEcardType = DIGITAL_DESIGN_TEMPLATE_CATEGORY_TO_ECARD_TYPE[ecardType];

      // If theme is a photo card, redirect to the standalone savethedate photo card editor
      if (mappedEcardType === EcardType.savethedate && getThemeInfoByThemeId(themeId)) {
        const photoEcardEditorRoute = ecardRoutePaths.design.goToPath(`savethedate`);
        ecardNestedPath = photoEcardEditorRoute;
      } else {
        const ecardEditorRoute = ecardRoutePaths.design.goToPath(`ecard/${mappedEcardType}`);
        ecardNestedPath = ecardEditorRoute;
      }

      const url = new URL(location.origin + (isPublicShopRoute ? `/event/edit/${ecardNestedPath}` : buildPath(ecardNestedPath)));
      url.searchParams.set('theme', themeId);
      url.searchParams.set('save', 'true');
      url.searchParams.set('ref', encodeURIComponent(location.pathname));

      return formatToUrl(url.toString());
    },
    [buildPath, formatToUrl, isPublicShopRoute]
  );
};

export const useCustomizeDigitalCard = (args: UseCustomizeDigitalCardArgs) => {
  const { buildPath } = useRouterHelper();

  const { pdpCustomizeNowClicked } = useCardTelemetry();

  const { stationeryTemplate, supportedFormats } = args;

  const ecardType = STATIONERY_TEMPLATE_TO_ECARD_TYPE[stationeryTemplate];

  const constructEcardEditorRedirectUrl = useConstructEcardEditorRedirectUrl();

  const handleOnCustomizeDigitalClick = useEventCallback(async (themeId: string) => {
    if (stationeryTemplate === StationeryTemplateCategoryEnum.invitation || stationeryTemplate === StationeryTemplateCategoryEnum.saveTheDate) {
      withWindow(global => {
        pdpCustomizeNowClicked({ themeId, supportedFormats, format: 'digital' });
        let url: URL;
        if (ecardType === 'savethedate' && getThemeInfoByThemeId(themeId)) {
          // Only save the dates have photo ecards at the moment.
          url = new URL(global.location.origin + buildPath(ecardRoutePaths.design.goToPath(`savethedate`)));
        } else {
          url = new URL(global.location.origin + buildPath(ecardRoutePaths.design.goToPath(`ecard/${ecardType}`)));
        }
        url.searchParams.set('theme', themeId);
        url.searchParams.set('save', 'true');
        url.searchParams.set('ref', encodeURIComponent(global.location.pathname));

        const redirectUrl = constructEcardEditorRedirectUrl(stationeryTemplate, themeId);

        // Perform a full page redirect so that `server.ts` can decide which ecard editor experience
        // the user should be redirected to.
        // global.location.href = formatToUrl(url.toString());
        global.location.href = redirectUrl;
      });
    }
  });

  return {
    handleOnCustomizeDigitalClick
  };
};
