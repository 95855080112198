import React from 'react';
import { SaveTheDateProvider } from '@apps/saveTheDate/state/context';
import { RouterSaveTheDate } from '@apps/saveTheDate/route.paths';
import { SAVE_THE_DATE_APP_NAME } from '@apps/saveTheDate/constants';
import { NavBar } from '@apps/saveTheDate/components/NavBar';
import Layout from '@apps/saveTheDate/components/Layout';
import { TelemetryProvider } from './SaveTheDate.telemetry';
import { LANDING_PAGE_PATH } from '@apps/saveTheDate/constants';
import { withWindow } from '@shared/utils/withWindow';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import DesignECardView from '@apps/postOffice/components/DesignECardView';
import DesignEmailView from '@apps/postOffice/components/DesignEmailView';
import OptionalTestEmailView from '@apps/postOffice/components/OptionalTestEmailView';
import SelectRecipientsView from '@apps/postOffice/components/SelectRecipientsView';
import SendAndTrackView from '@apps/postOffice/components/SendAndTrackView';
import { BottomSheetProvider } from '@shared/components/BottomSheet';
import { useHistory } from '@shared/core';
import SelectDeliveryMethodView from '@apps/postOffice/components/SelectDeliveryMethodView';
import ShareWithLinkView from '@apps/postOffice/components/ShareWithLinkView';

interface SaveTheDateAppProps
  extends Readonly<{
    eventId: string;
    eventHandle: string;
    eventDisplayName: string;
  }> {}

const SaveTheDateApp: React.FC<SaveTheDateAppProps> = props => {
  const isMobile = useIsMobileScreen();
  const history = useHistory();
  const isInEditor = ['/design', '/email', '/confirm'].some(path => history.location.pathname.endsWith(path));

  const handleBottomSheetChange = (route: string) => {
    if (isInEditor && !history.location.pathname.endsWith(route)) {
      history.replace(route);
    }
  };

  const onBackClick = () => {
    const searchParams = new URLSearchParams(location.search);
    const ref = searchParams.get('ref');

    if (ref) {
      withWindow(() => {
        window.location.href = `${window.location.origin}${decodeURIComponent(ref)}`;
      });

      return;
    }

    withWindow(() => {
      window.location.href = `${window.location.origin}/${props.eventHandle}/edit/${LANDING_PAGE_PATH}`;
    });
  };

  return (
    <TelemetryProvider>
      <BottomSheetProvider
        views={[
          { id: 'card', children: <DesignECardView onVisible={() => handleBottomSheetChange('design')} /> },
          { id: 'email', children: <DesignEmailView onVisible={() => handleBottomSheetChange('email')} /> },
          { id: 'delivery-method', children: <SelectDeliveryMethodView />, disableNextButton: true },
          { id: 'share-with-link', children: <ShareWithLinkView />, backButtonDirection: 'delivery-method', disableNextButton: true },
          { id: 'send-by-email', children: <SelectRecipientsView />, backButtonDirection: 'delivery-method' },
          { id: 'test-email', children: <OptionalTestEmailView /> },
          { id: 'send', children: <SendAndTrackView /> }
        ]}
      >
        <SaveTheDateProvider {...props}>
          <Layout onBackClick={onBackClick}>
            <RouterSaveTheDate appUrl={SAVE_THE_DATE_APP_NAME} handle={props.eventHandle} isInEditor={isInEditor} />
            {!isMobile && <NavBar />}
          </Layout>
        </SaveTheDateProvider>
      </BottomSheetProvider>
    </TelemetryProvider>
  );
};

export default SaveTheDateApp;
