import { Box } from '@withjoy/joykit';
import React, { CSSProperties } from 'react';
import { useFont } from '@apps/card/hooks/useFont';
import { LayerFontData, TextLayerData } from './Layer.types';
import { ContentEditable } from './ContentEditable';
import { PageEditor } from './Page';
import { layerLayoutDataToCSS } from './ImageLayer';
import { StationeryDraftFormat } from '@graphql/generated';
import { isValidUrl } from '@shared/utils/urlHelpers';

// Copied from Canvas Service. Keep in sync.
export function fontShorthand(props: LayerFontData, noDefFallback = false) {
  const { style, weight, size, lineHeight, family } = props;
  const familyList = noDefFallback ? `'${family}', 'Adobe NotDef'` : `'${family}'`;
  return [style, weight, [`${size}px`, lineHeight?.toFixed(2)].filter(x => x).join('/'), familyList].filter(x => x).join(' ');
}

// For right-aligned centered text, Figma does not consider/measure the trailing space.
// This is most obvious with large single characters such as "&". This corrects the rendering to match Figma.
function alignmentCorrection(align: 'left' | 'center' | 'right' | 'justify', letterSpacing: number, fontFamily: string) {
  // Specifically targeting our left-aligned Pinyon Script text, which happens to be 12px in most cases.
  // In the future, we will make this variable based on the font size.
  if (align === 'left' && fontFamily === 'Pinyon Script') return 'translateX(-3.4px)';
  if (align === 'center') return `translateX(${letterSpacing * 0.5}em)`;
  if (align === 'right') return `translateX(${letterSpacing}em)`;
  return undefined;
}

export function textLayerDataToCSS(textData: Pick<TextLayerData['textData'], 'fill' | 'font' | 'text'>, noDefFallback = false) {
  const { fill, font, text } = textData;
  return {
    // From LayerData.
    color: fill ?? '#5C5C5C',
    font: fontShorthand(font, noDefFallback),
    letterSpacing: `${text.letterSpacing}em`,
    wordSpacing: font.family === 'Pinyon Script' ? '0.05em' : '0em',
    textTransform: text.case,
    textAlign: text.alignHorizontal,

    // General Corrections and Adjustments.
    transform: alignmentCorrection(text.alignHorizontal, text.letterSpacing, font.family),
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word', // Mirror skia canvas handling of long words.
    // WebkitTextStrokeWidth: 0.15,

    // Must come after 'font: fontShorthand(...)' property, otherwise that property
    // will override this setting. Not breaking apart the 'font' property as Skia only takes
    // the shorthand syntax, and prefer to keep fontShorthand() the same for both platforms.
    fontFeatureSettings: text.letterSpacing === 0 ? '"calt", "liga"' : '"calt"'
  } as CSSProperties;
}

const applyLinkStyleByFormat = (format: StationeryDraftFormat): CSSProperties => {
  if (format === StationeryDraftFormat.digital) {
    return {
      fontWeight: 'bold',
      textDecoration: 'underline 0.5px #D9D9D9',
      textUnderlineOffset: '4px'
    };
  }

  return {};
};

export const TextLayer = (props: { layerIndex: number; layer: TextLayerData; editor?: PageEditor; format?: StationeryDraftFormat }) => {
  const { layerIndex, layer, editor, format = StationeryDraftFormat.print } = props;
  const { content, font } = layer.textData;

  // The "the" on Gold Confetti Save the Date is good test for italics font loading.
  useFont(`${font.family}:${font.weight}${font.style ?? ''}`);
  useFont('Adobe NotDef'); // Would like to call this conditionally, but hooks. :( But it won't hurt to leave this here.

  const isInEditor = !!editor;
  const isUrlLayer = layer.name === 'URL' && isValidUrl(content);

  const isEditing = editor?.isEditing(layerIndex) ?? false;
  const isActive = editor?.isActive(layerIndex) ?? false;

  // Most edits will be made while the layer is selected, and we update the height to match the content.
  // So 99% of the time 'height=layer.layout.height' vs 'height=unset' won't matter.
  // When we do template text replacement, the 'height=unset' may come in handy, since we are not actively updating the height.
  const style = {
    ...layerLayoutDataToCSS(layer.layout),
    ...textLayerDataToCSS(layer.textData, format === StationeryDraftFormat.print)
  } as CSSProperties;

  const notEditingStyle = {
    ...style,
    height: 'unset',
    ...(isUrlLayer ? applyLinkStyleByFormat(format) : {})
  } as CSSProperties;

  const cursor = (() => {
    if (!layer.editable) return 'default';
    if (isEditing) return 'text';
    if (isActive) return 'move';
    return 'default';
  })();

  return isEditing ? (
    <ContentEditable
      style={style}
      value={content}
      isEditing={isEditing}
      onChange={value => {
        editor?.updateLayer(layerIndex, draft => {
          if (draft.type !== 'text') return;
          draft.textData.content = value;
        });
      }}
    />
  ) : (
    <Box
      className="introable"
      pointerEvents={layer.editable ? 'auto' : 'none'}
      // Surface touchAction is auto (by default) to allow browser handling of all panning and zooming gestures.
      // However we don't want panning during drag move (isActive), so we set it to none here.
      touchAction={isActive ? 'none' : 'auto'}
      style={notEditingStyle}
      {...(isUrlLayer && !isInEditor ? { as: 'a', href: content, cursor: 'pointer' } : { cursor })}
    >
      {`${content}\n`}
    </Box>
  );
  // https://stackoverflow.com/questions/43492826/why-does-the-browser-not-render-a-line-break-caused-by-a-trailing-newline-charac
};
