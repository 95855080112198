import { AccommodationPlaceV2Fragment } from '@graphql/generated';

export enum TravelMapBlockType {
  travelMapVenue = 'travelMapVenue',
  travelMapAccommodationsHotel = 'travelMapAccommodationsHotel',
  travelMapAccommodationsCustom = 'travelMapAccommodationsCustom',
  travelMapAccommodationsRoomblock = 'travelMapAccommodationsRoomblock'
}

export type CustomPropertyType = AccommodationPlaceV2Fragment;

export type HotelType = AccommodationPlaceV2Fragment;

export type Venue = {
  id?: string;
  locationValue?: string;
  name?: string | null;
  address?: string | null;
  postalCode?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  [key: string]: unknown;
};
export type VenueType = Venue;

export interface ReservedRoomBlocks {
  id?: string;
  displayName?: string | null;
  address?: string | null;
  longitude?: number | null;
  latitude?: number | null;
  isFavorite?: boolean | null;
  hotelMoreInfoUrl?: string | null;
  bookingUrl?: string | null;
  starRating?: number | null;
  strikeoutPricePerNight?: number | null;
  pricePerNight?: number | null;
  checkInDate?: string | null;
  checkOutDate?: string | null;
  cutoffDate?: string | null;
  note?: string | null;
  photo?: { __typename?: 'Photo'; url: string } | null;
  numberOfRoomsBooked?: number | null;
  numberOfRoomsGuestBooked?: number | null;
  numberOfNightsBooked?: number | null;
  numberOfNightsGuestBooked?: number | null;
}
export type RoomBlocksType = ReservedRoomBlocks;

export interface AccommodationItemList {
  id: string;
  type: TravelMapBlockType;
  isFavorite?: boolean | null;
  photoUrl?: string | null;
  address?: string | null;
  cutoffDate?: string | null;
  displayName?: string | null;
  isPhotoHidden?: boolean;
  description?: string | null;
  lat?: number | null;
  lng?: number | null;
  rating?: number | null;
  phone?: string | null;
  placeId?: string | null;
  price?: number | null;
  strikeoutPrice?: number | null;
  websiteUrl?: string;
}
