import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import React from 'react';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { Link } from '@shared/core';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useExitWithoutCreatingDraftHandler } from '../useExitWithoutCreatingDraftHandler';
import { ChevronBack } from '@withjoy/joykit/icons';
import { Skeleton } from '@shared/components/Skeleton';

const CAT_TO_TEXT = {
  [StationeryTemplateCategoryEnum.saveTheDate]: 'Save the Dates',
  [StationeryTemplateCategoryEnum.invitation]: 'Invitations',
  [StationeryTemplateCategoryEnum.thankYou]: 'Thank You Cards',
  [StationeryTemplateCategoryEnum.holiday]: 'Holiday Cards',
  [StationeryTemplateCategoryEnum.enclosure]: 'Enclosure Cards'
};

const Slash = () => <Box as="span">/</Box>;

const DesktopContainer: React.FC = ({ children }) => {
  return (
    <Flex
      typographyVariant="hed2"
      __css={{
        [' > *']: {
          paddingX: 4,
          paddingY: 1
        }
      }}
    >
      {children}
    </Flex>
  );
};

export const Breadcrumbs = ({ designName, templateCategory }: { designName: string; templateCategory: StationeryTemplateCategoryEnum }) => {
  const isMobile = useIsMobileScreen();
  const { getShopPath } = useCardsRouterContext();

  const onClickRoot = useExitWithoutCreatingDraftHandler('breadcrumbsRootLink');
  const onClickCategory = useExitWithoutCreatingDraftHandler('breadcrumbsCategoryLink');

  return isMobile ? (
    <Flex as={Link} display="inline-flex" alignItems="center" to={getShopPath('wedding', templateCategory)} onClick={onClickCategory} columnGap={3}>
      <ChevronBack size={16} />
      <TextV2 typographyVariant="hed2">{CAT_TO_TEXT[templateCategory]}</TextV2>
    </Flex>
  ) : (
    <DesktopContainer>
      <Box as={Link} to={getShopPath('wedding')} onClick={onClickRoot}>
        Cards
      </Box>
      <Slash />
      <Box as={Link} to={getShopPath('wedding', templateCategory)} onClick={onClickCategory}>
        {CAT_TO_TEXT[templateCategory]}
      </Box>
      <Slash />
      <Box as="span" color="mono10" cursor="default">
        {designName}
      </Box>
    </DesktopContainer>
  );
};

const BreeadcrumbsSkeleton = () => {
  const isMobile = useIsMobileScreen();

  return isMobile ? (
    <Flex display="inline-flex" alignItems="center" columnGap={3}>
      <Skeleton />
      <Skeleton />
    </Flex>
  ) : (
    <DesktopContainer>
      <Skeleton width={60} />
      <Slash />
      <Skeleton width={120} />
      <Slash />
      <Skeleton width={200} />
    </DesktopContainer>
  );
};

Breadcrumbs.Skeleton = BreeadcrumbsSkeleton;
