import ImagineYourCardRoot from '@apps/imagineYourCard/Components/ImagineYourCardRoot/ImagineYourCardRoot';
import { AICardRouter } from '@apps/imagineYourCard/router';
import React from 'react';
import { CardCategoryProvider } from '../CardCategoryProvider';
import { PrimaryNav } from '../PrimaryNav';
import { Layout } from '@apps/card/components/Layout';
import { UniversalBurgerMenu } from '@apps/admin/components/UniversalBurgerMenu/UniversalBurgerMenu';
import { Hamburger } from '@withjoy/joykit/icons';
import { Flex } from '@withjoy/joykit';
import { useHandleAdminOnCustomizeNowClick } from './hooks/useHandleAdminOnCustomizeNowClick';

export const ImagineYourCard = () => {
  const { handleAdminOnCustomizeNowClick } = useHandleAdminOnCustomizeNowClick();

  return (
    <CardCategoryProvider>
      <AICardRouter>
        <Layout.Grid>
          <Layout.Main>
            <Layout.Header
              paddingVariant="dashboard"
              startIcon={() => (
                <UniversalBurgerMenu>
                  <Hamburger size="lg" />
                </UniversalBurgerMenu>
              )}
              alignment="flex-start"
            >
              <PrimaryNav />
            </Layout.Header>
            <Flex flexDirection="column">
              <ImagineYourCardRoot handleCustomizeNowClickOverride={handleAdminOnCustomizeNowClick} />
            </Flex>
          </Layout.Main>
        </Layout.Grid>
      </AICardRouter>
    </CardCategoryProvider>
  );
};
