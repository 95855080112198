import React, { useCallback, useEffect } from 'react';
import { Gallery } from '../Gallery';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { Skeleton, SkeletonGroup } from '@shared/components/Skeleton';
import { useCardCategoryContext } from '../CardCategoryProvider';
import { GalleryTile } from '../GalleryTile';
import { PageSection } from '../PageSection';
import { useCardTelemetry, TelemetryProvider, enrichCardTelemetryExtraInfo } from '@apps/card/Card.telemetry';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useDesignTemplates } from './useDesignTemplates';
import { Box, Flex } from '@withjoy/joykit';
import { ConciergeServiceSection } from '../ConciergeServiceSection/ConciergeServiceSection';
import usePropValues from './usePropValues';
import { TravelBookingDialogCrossSell } from '../TravelBookingDialogCrossSell/TravelBookingDialogCrossSell';
import { useNativeState } from '@shared/core/native';
import { CustomizeNowClickOverrideHandler, StationeryTemplate } from './DesignsGallery.types';
import { useUrlSyncedFilters } from './useUrlSyncedFilters';
import { CardEventType, GalleryItem } from './components/GalleryItem/GalleryItem';
import { pxToRem } from '@withjoy/joykit/theme';
import { CardDiscountProvider } from './useCardDiscountContext';
import { useConciergeDisclosure } from './useConciergeDisclosure';
import { BackToTop } from '@apps/registry/common/components/Catalog/components/BackToTop';
import { FilterFacet } from './components/Filters/Filter.types';
import { DesignGalleryFiltersProvider } from './DesignGalleryFiltersProvider';
import { useApplySearchFiltersToTemplates } from './useApplySearchFiltersToTemplates';
import { SearchAndFiltersContainer } from './components/Filters/SearchAndFilterContainer';
import { useAuth } from '@shared/components/AuthProvider';
import { useQueryParamHelper } from '@shared/core/queryString';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { useStationeryPagination } from './useStationeryPagination';
import { GalleryPagination } from './components/GalleryPagination';
import { useCardCarouselValues } from './components/GalleryItem/useCardCarouselValues';

interface DesignsGalleryProps {
  disableTravelCrossSellDialog?: boolean;

  eventId?: string;
  eventHandle?: string;
  // Intended only to be used for the investor demo.
  onCustomizeCardClickOverride?: CustomizeNowClickOverrideHandler;
}

const Root = ({
  filterFacets,
  disableTravelCrossSellDialog,
  eventHandle,
  eventId,
  eventDesignThemeId,
  filterMatchCounts,
  onCustomizeCardClickOverride,
  templates
}: {
  filterFacets: FilterFacet[];
  templates: StationeryTemplate[];
  eventDesignThemeId: string | undefined;
  filterMatchCounts: Record<string, number>;
} & DesignsGalleryProps) => {
  useUrlSyncedFilters();
  useCardTelemetry({ page: 'homeRoute' });
  const { currentCategory } = useCardCategoryContext();
  enrichCardTelemetryExtraInfo({ stationeryTemplateCategory: currentCategory });
  useEffect(() => {
    enrichCardTelemetryExtraInfo({ stationeryTemplateCategory: null });
  }, []);
  const { isNative } = useNativeState();
  const { useSearchResults } = useApplySearchFiltersToTemplates();
  const searchResults = useSearchResults();
  const { resultTags, searchCompleted } = searchResults;
  const isMobileOrTablet = useIsMobileOrTablet();

  const disableAssetRotationOnHover = isMobileOrTablet || isNative;

  const { value: isStDPaperlustConciergeEnabled } = useFeatureValue('printStDPaperlustConciergeEnabled');

  const { value: freeDigitalPriceStringExperimentValue } = useFeatureValue('printGalleryShowFreeDigitalPricing');
  const isFreeDigitalPriceStringExperimentEnabled = freeDigitalPriceStringExperimentValue === 'on';

  const isViewingCategoryWithConciergeService = [
    StationeryTemplateCategoryEnum.invitation,
    isStDPaperlustConciergeEnabled === 'on' ? StationeryTemplateCategoryEnum.saveTheDate : null
  ].includes(currentCategory);

  const isPaperlustConciergeServiceEnabled =
    useFeatureValue('printPaperlustConciergeServiceCarousel', {
      skip: !isViewingCategoryWithConciergeService
    }).value === 'on';

  const isPaperlustConciergeServiceGallery =
    useFeatureValue('printPaperlustShowConciergeServiceInGallery', {
      skip: !isViewingCategoryWithConciergeService || !isPaperlustConciergeServiceEnabled
    }).value === 'on';

  const templatesWithProps = usePropValues({ category: currentCategory, templates, conciergeEnabled: isPaperlustConciergeServiceGallery && !searchCompleted });
  const { items: paginatedTemplates, ...pagination } = useStationeryPagination(templatesWithProps);
  const cardCarouselSlides = useCardCarouselValues();

  const {
    isOpen: isConciergeModalOpen,
    onOpen: openConciergeModal,
    onClose: closeConciergeModal,
    selectedOffering: conciergeSelectedOffering,
    handleOnTileClick: conciergeHandleOnTileClick
  } = useConciergeDisclosure();

  const handleCardEvents = useCallback(
    (event: CardEventType) => {
      switch (event.type) {
        case 'conciergeselectedevent':
          conciergeHandleOnTileClick(event.id);
          break;
      }
    },
    [conciergeHandleOnTileClick]
  );

  const showFilters = ![StationeryTemplateCategoryEnum.thankYou, StationeryTemplateCategoryEnum.enclosure, StationeryTemplateCategoryEnum.holiday].includes(currentCategory);

  const { currentUser } = useAuth();
  const isSuperAdmin = Boolean(currentUser && currentUser.profile?.superAdmin);

  const params = useQueryParamHelper();
  const showSearchDebugInfo = isSuperAdmin && params.getValue('searchDebug') === 'true';

  return (
    <>
      {!disableTravelCrossSellDialog && !isNative && eventId && eventHandle && <TravelBookingDialogCrossSell eventId={eventId} eventHandle={eventHandle} />}
      {isPaperlustConciergeServiceEnabled && (
        <ConciergeServiceSection
          handleOnTileClick={conciergeHandleOnTileClick}
          isOpen={isConciergeModalOpen}
          onOpen={openConciergeModal}
          onClose={closeConciergeModal}
          selectedOffering={conciergeSelectedOffering}
          onCustomizeOfferingOverride={onCustomizeCardClickOverride}
          category={currentCategory}
          enableCarousel={!isPaperlustConciergeServiceGallery}
        />
      )}
      <PageSection id="designs-gallery" paddingX={0} titleMarginX={{ _: 6, md: 8 }}>
        {templates && (
          <>
            <SearchAndFiltersContainer showFilters={showFilters} filterFacets={filterFacets} />
            <Gallery>
              {paginatedTemplates.map((data, index) => {
                return (
                  <GalleryItem
                    cardTileSource="shop"
                    eventId={eventId}
                    tagCount={resultTags?.length}
                    disableAssetRotationOnHover={disableAssetRotationOnHover}
                    onCardSelect={handleCardEvents}
                    key={index}
                    data={data}
                    eventDesignThemeId={eventDesignThemeId}
                    cardCarouselSlides={cardCarouselSlides}
                    showSearchDebugInfo={showSearchDebugInfo}
                    enableFreePriceString={isFreeDigitalPriceStringExperimentEnabled}
                  />
                );
              })}
            </Gallery>
            <GalleryPagination currentPage={pagination.page} pageCount={pagination.pages} onPageChange={pagination.setPage} />
          </>
        )}
      </PageSection>
    </>
  );
};

export const DesignsGallery = ({ disableTravelCrossSellDialog, eventId, eventHandle, onCustomizeCardClickOverride }: DesignsGalleryProps) => {
  const { filterMatchCounts, filterFacets, templates, eventDesignThemeId } = useDesignTemplates({ eventId, eventHandle });

  return (
    <SkeletonGroup isReady={!!templates.length} placeholder={<DesignsGallerySkeleton />}>
      <CardDiscountProvider>
        <TelemetryProvider>
          <DesignGalleryFiltersProvider>
            <Root
              disableTravelCrossSellDialog={disableTravelCrossSellDialog}
              filterMatchCounts={filterMatchCounts}
              templates={templates}
              eventDesignThemeId={eventDesignThemeId}
              eventId={eventId}
              eventHandle={eventHandle}
              onCustomizeCardClickOverride={onCustomizeCardClickOverride}
              filterFacets={filterFacets}
            />
            <BackToTop />
          </DesignGalleryFiltersProvider>
        </TelemetryProvider>
      </CardDiscountProvider>
    </SkeletonGroup>
  );
};

const DesignsGallerySkeleton = () => {
  const { currentCategory } = useCardCategoryContext();

  const { value: isStDPaperlustConciergeEnabled } = useFeatureValue('printStDPaperlustConciergeEnabled');

  const isViewingCategoryWithConciergeService = [
    StationeryTemplateCategoryEnum.invitation,
    isStDPaperlustConciergeEnabled === 'on' ? StationeryTemplateCategoryEnum.saveTheDate : null
  ].includes(currentCategory);

  const isPaperlustConciergeServiceEnabled =
    useFeatureValue('printPaperlustConciergeServiceCarousel', {
      skip: !isViewingCategoryWithConciergeService
    }).value === 'on';

  const isPaperlustConciergeServiceGallery =
    useFeatureValue('printPaperlustShowConciergeServiceInGallery', {
      skip: !isViewingCategoryWithConciergeService || !isPaperlustConciergeServiceEnabled
    }).value === 'on';

  return (
    <>
      {isPaperlustConciergeServiceEnabled && !isPaperlustConciergeServiceGallery && (
        <Box marginBottom={pxToRem(74)}>
          <ConciergeServiceSection.Skeleton category={currentCategory} />
        </Box>
      )}

      <PageSection
        id="design-gallery-skeleton"
        paddingX={0}
        spacing={{ _: 7, md: 9 }}
        title={() =>
          isViewingCategoryWithConciergeService ? (
            <Box>
              <Skeleton width={270} height={7} marginX={{ _: 6, md: 8 }} />
            </Box>
          ) : (
            <Flex justifyContent="center" flexDirection="column" alignItems="center" rowGap={5}>
              <Skeleton width={340} height={36} />
              <Skeleton width={400} height={18} />
              <Skeleton width={280} height={18} />
            </Flex>
          )
        }
      >
        <Gallery>
          {Array.from({ length: 8 }).map((_, index) => (
            <GalleryTile.Skeleton key={index} />
          ))}
        </Gallery>
      </PageSection>
    </>
  );
};
