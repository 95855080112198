import { StationeryDraftFormat, StationeryTemplateCategoryEnum } from '@graphql/generated';
import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { LayerType } from './components/Layer.types';
import { NavigationStep } from './CardCustomizer.types';
import { AddressType } from './components/AddressDialog';
import { PromotionConfig } from './usePromotionConfig';

const CATEGORY = 'messaging.card';
const PAGE_PREFIX = 'admin';
const PAGE = 'customize';

export interface CustomizePageArgs {
  category: StationeryTemplateCategoryEnum;
  themeId: string;
  step: 'cardFront' | 'cardBack' | 'envelope' | 'review' | 'postCheckout';
}

export interface CustomizationButtonInteractedArgs {
  property: string;
  value?: string | number | boolean;
}

export interface UnableToCheckoutErrorArgs extends Record<string, unknown> {
  action?: 'DigitalCardCheckout' | 'InternationalDigitalCardCheckout';
  name: string;
  error?: unknown;
}

const cardTelemetry = createTelemetryObject({
  pages: {
    customize: () => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE
    }),
    premiumDigitalEmailEditorDialog: () => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: 'premiumDigitalEmailEditorDialog'
    })
  },
  actions: {
    customizationButtonInteracted: (args: CustomizationButtonInteractedArgs) => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: args.property,
        value: args.value
      }
    }),
    stickyNextButtonWithValidationInteracted: (isValid: boolean) => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'stickyCta_nextButton_withValidation',
        isValid
      }
    }),
    cardLayerFocused: (layerType: LayerType) => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'CardLayerFocused',
      extraInfo: {
        layerType
      }
    }),
    loadFontError: (family: string, error: unknown) => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'loadFontError',
      actionType: 'error',
      extraInfo: {
        family,
        error
      }
    }),
    photoSelectionError: (photo: unknown) => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'PhotoSelectionError',
      actionType: 'error',
      extraInfo: {
        photo
      }
    }),
    promoCodeInputButtonInteracted: (args: { promoCode: string; element: 'applyCta' | 'removeCta'; isValid?: boolean; promotion: PromotionConfig | undefined }) => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: `promoCodeInput_${args.element}`,
        isValid: args.isValid,
        promotion: args.promotion,
        promoCode: args.promoCode
      }
    }),
    stalePromoCodeDetected: (args: { promoCode: string; promotion: PromotionConfig | undefined }) => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'SavedPromoCodeInvalidated',
      extraInfo: {
        savedPromoCode: args.promoCode,
        promotion: args.promotion
      }
    }),
    unableToCheckoutError: (args: UnableToCheckoutErrorArgs) => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: args.action || 'CardCheckout',
      actionType: 'error',
      extraInfo: { ...args }
    }),
    unableToFetchSmartySuggestions: (error: unknown) => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'SmartyApi',
      actionType: 'error',
      extraInfo: {
        error
      }
    }),
    fetchedSmartySuggestions: (addressType: AddressType, suggestionCount: number) => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'SmartyApiSuccess',
      extraInfo: {
        name: `${addressType}_suggestionsFetched`,
        suggestionCount
      }
    }),
    exitCustomizationClicked: (name: 'openCta' | 'cancel' | 'viewDraftsCta' | 'viewDesignsCta') => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: `exitCustomizationWarning_${name}`
      }
    }),
    stripeRedirectTimeoutError: () => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'MessagingStripeRedirectTimeout',
      actionType: 'error'
    }),
    editCardOrderClicked: () => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name: 'submitChangesCta'
      }
    }),

    messagingButtonInteracted: (name: string) => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'MessagingButtonInteracted',
      extraInfo: {
        name
      }
    }),

    // Premium Digital Flow
    inputFieldFocused: (fieldIdentifier: string) => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'FormFieldInteracted',
      extraInfo: {
        inputAction: 'onFocus',
        fieldIdentifier
      }
    }),

    unableToSendPremiumDigitalCardError: (name: 'testCard' | 'finalizedCard', error: unknown) => ({
      category: CATEGORY,
      pagePrefix: PAGE_PREFIX,
      page: PAGE,
      action: 'SendPremiumDigitalCard',
      actionType: 'error',
      extraInfo: {
        name,
        error
      }
    })
  }
});

type EnrichedCardCustomizerExtraInfo = {
  stationeryDraftId: string | null;
  stationeryDraftFormat: StationeryDraftFormat | null;
  stationeryTemplateCategory: StationeryTemplateCategoryEnum | null;
  themeId: string | null;
  step: NavigationStep | null;
  pageOverride: Maybe<'pdpModal'>;
  orderNumber: Maybe<string>;
};

const {
  TelemetryProvider: CardCustomizerTelemetryProvider,
  useTelemetry: useCardCustomizerTelemetry,
  enrichTelemetryExtraInfo: enrichCardCutomizerTelemetryExtraInfo
} = createTelemetry(cardTelemetry, undefined, undefined, {
  stationeryDraftId: null,
  stationeryTemplateCategory: null,
  themeId: null,
  step: null,
  orderNumber: null,
  stationeryDraftFormat: null
} as EnrichedCardCustomizerExtraInfo);

export { CardCustomizerTelemetryProvider, useCardCustomizerTelemetry, enrichCardCutomizerTelemetryExtraInfo };
