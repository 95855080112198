import { Flex, TextV2 } from '@withjoy/joykit';
import React, { useState } from 'react';
import { SearchRequestState, useApplySearchFiltersToTemplates } from '../../useApplySearchFiltersToTemplates';
import { SearchBarContainer, SearchBarInput, ShimmerLoadingAnimation } from './SearchBar.styles';
import { Close } from '@withjoy/joykit/icons';
import { ButtonContainer } from './components/ButtonContainer';
import { useQueryParamHelper } from '@shared/core/queryString';
import { useAuth } from '@shared/components/AuthProvider';
import Search from './components/Search';

const SEARCH_TERM_LENGTH_LIMIT = 25;

const stateToText = (state: SearchRequestState) => {
  const dict = {
    0: 'Ready for input',
    1: 'Loading',
    2: 'Done! Ready for input.',
    3: 'Error -- try again'
  };
  return dict[state];
};

export const SearchBar = ({ showFilters }: { showFilters?: boolean }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { makeSearchRequest, clearSearch, useSearchResults, loadingState } = useApplySearchFiltersToTemplates();
  const searchResults = useSearchResults();
  const { resultTags, searchError } = searchResults;

  const isLoading = loadingState.current === SearchRequestState.LOADING;

  const { currentUser } = useAuth();
  const isSuperAdmin = Boolean(currentUser && currentUser.profile?.superAdmin);

  const params = useQueryParamHelper();
  const showDebugInfo = isSuperAdmin && params.getValue('searchDebug') === 'true';

  const submit = () => {
    makeSearchRequest(searchQuery);
  };

  return (
    <Flex flexDirection="column" marginLeft={showFilters ? 'unset' : ['1.5rem', '2.5rem']} marginRight={['1.5rem', 'unset']}>
      <SearchBarContainer>
        <SearchBarInput
          placeholder="Search..."
          value={searchQuery}
          onChange={e => {
            if (!isLoading) {
              if (e.target.value.length <= SEARCH_TERM_LENGTH_LIMIT) {
                setSearchQuery(e.target.value);
              }
            }
          }}
          onKeyPress={event => {
            if (event.key === 'Enter' && !isLoading) {
              submit();
            }
          }}
        />
        <ButtonContainer
          onClick={() => {
            clearSearch();
            setSearchQuery('');
          }}
          hidden={!searchResults.searchCompleted && searchQuery === '' && !searchError}
          disabled={isLoading && !searchError}
        >
          <Close size={16} color="mono14" />
        </ButtonContainer>
        <ButtonContainer
          onClick={() => {
            submit();
          }}
          disabled={isLoading}
        >
          <Search size={16} color="mono14" />
        </ButtonContainer>
        <ShimmerLoadingAnimation $loading={isLoading} />
      </SearchBarContainer>
      {showDebugInfo && resultTags && <TextV2>Tags: {resultTags?.toString()}</TextV2>}
      {showDebugInfo && loadingState.current && <TextV2>{stateToText(loadingState.current)}</TextV2>}
    </Flex>
  );
};
