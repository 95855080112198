import React, { useMemo } from 'react';
import queryString from 'query-string';
import { DialogV2, Flex, TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core/i18n';
import { ResponsiveMarginProps } from '@withjoy/joykit/styled-system';
import { dialogOverridesDesktop, dialogOverridesMobile } from './BookingAssistant.styles';
import { SpacingStack } from '@withjoy/joykit';
import { ReactComponent as Booking } from './assets/booking.svg';
import { ReactComponent as Hotels } from './assets/hotels.svg';
import { ReactComponent as Expedia } from './assets/expedia.svg';
import { ReactComponent as Vrbo } from './assets/vrbo.svg';
import { useAuth } from '@shared/components/AuthProvider';
import { config } from '@static/js/env.config';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { JoyLogo } from '@assets/index';
import { format } from 'date-fns-tz';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useEventInfo } from '@shared/utils/eventInfo';
import { pxToRem } from '@withjoy/joykit/theme';

export interface PlaceProps {
  id: 'joy' | 'vrbo' | 'booking' | 'expedia' | 'hotels' | 'travelRedirect';
  site: string;
  logo: React.ReactNode;
  subtitle?: string;
}

export interface BookingAssistantProps
  extends Readonly<{
      address: Maybe<string>;
      bookingType: Maybe<string>;
      eventId: string;
      guests: number;
      startDate?: number;
      endDate?: number;
      placeId: Maybe<string>;
      lng: Maybe<number> | undefined;
      lat: Maybe<number> | undefined;
      timezone: Maybe<string>;
      isOpen: boolean;
      onClose: () => void;
      onProviderClicked?: (checkIn: Maybe<string>, checkOut: Maybe<string>, guests: Maybe<string>, url: Maybe<string>, provider: Maybe<string>) => void;
    }>,
    ResponsiveMarginProps {}

export const places: PlaceProps[] = [
  {
    id: 'vrbo',
    site: 'vrbo.com',
    logo: <Vrbo />
  },
  {
    id: 'expedia',
    site: 'expedia.com',
    logo: <Expedia />
  },
  {
    id: 'hotels',
    site: 'hotels.com',
    logo: <Hotels />
  }
];

const BookingAssistantContent: React.FC<BookingAssistantProps> = ({
  address,
  bookingType,
  eventId,
  guests,
  startDate,
  endDate,
  placeId,
  lng,
  lat,
  timezone,
  isOpen,
  onClose,
  onProviderClicked
}) => {
  const { currentUser } = useAuth();
  const { eventInfo } = useEventInfo();
  const { t } = useTranslation('bookingAssistant');
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });

  const isNotUSAEvent = !eventInfo ? false : !eventInfo.locationInfo ? false : eventInfo?.locationInfo?.country !== 'United States';

  const placesAsPerLocation: PlaceProps[] = useMemo(() => {
    return places.map(item => {
      if (isNotUSAEvent && item.id === 'expedia') {
        return {
          id: 'booking',
          site: 'booking.com',
          logo: <Booking />
        };
      }
      return item;
    });
  }, [isNotUSAEvent]);

  const zonedStartDate = startDate && new Date(startDate);
  const zonedEndDate = endDate && new Date(endDate);

  const getProviderPath = useEventCallback((provider: PlaceProps['id']) => {
    switch (provider) {
      case 'joy':
        const checkIn = (zonedStartDate && format(zonedStartDate, 'yyyyMMdd')) || '';
        const checkOut = (zonedEndDate && format(zonedEndDate, 'yyyyMMdd')) || '';

        const queryToAppend: {
          checkin?: string;
          checkout?: string;
          campaignid?: string;
          roompax?: string;
        } = {
          campaignid: eventId || ''
        };

        if (checkIn) {
          queryToAppend['checkin'] = checkIn;
        }
        if (checkOut) {
          queryToAppend['checkout'] = checkOut;
        }
        if (guests) {
          queryToAppend['roompax'] = guests.toString();
        }

        return queryString.stringifyUrl({
          url: `https://hotels.withjoy.com`,
          query: queryToAppend
        });
      case 'expedia':
        const expediaStartDate = (zonedStartDate && format(zonedStartDate, 'yyyy-MM-dd')) || '';
        const expediaEndDate = (zonedEndDate && format(zonedEndDate, 'yyyy-MM-dd')) || '';
        return queryString.stringifyUrl({
          url: `${config.redirectServiceUri}/redirect`,
          query: {
            url: `https://www.expedia.com/Hotel-Search?destination=${address}&regionId=${placeId}&latLong=${lat}%2C${lng}&startDate=${expediaStartDate}&endDate=${expediaEndDate}&adults=${guests}&sort=RECOMMENDED`
          }
        });
      case 'vrbo':
        const vrboStartDate = (zonedStartDate && format(zonedStartDate, 'yyyy-MM-dd')) || '';
        const vrboEndDate = (zonedEndDate && format(zonedEndDate, 'yyyy-MM-dd')) || '';
        return queryString.stringifyUrl({
          url: `${config.redirectServiceUri}/redirect`,
          query: {
            url: `https://www.vrbo.com/search?destination=${address}&regionId=${placeId}&latLong=${lat}%2C${lng}&startDate=${vrboStartDate}&endDate=${vrboEndDate}&adults=${guests}&sort=RECOMMENDED`
          }
        });
      default:
        return queryString.stringifyUrl({
          url: `${config.redirectServiceUri}/travelredirect`,
          query: {
            provider,
            guests: `${guests}`,
            dtstart: `${startDate}`,
            dtend: `${endDate}`,
            tzid: timezone ?? '',
            address: address ?? '',
            placeId: placeId ?? '',
            lng: `${lng}`,
            lat: `${lat}`,
            bookingType: bookingType ?? '',
            platform: 'web',
            eventId: eventId || '',
            ...(currentUser?.profile?.id ? { userId: currentUser.profile.id } : {})
          }
        });
    }
  });

  const handleClick = useEventCallback((provider: PlaceProps['id'], path: string) => () => {
    onClose();
    onProviderClicked && onProviderClicked(new Date(startDate || '').toString(), new Date(endDate || '').toString(), guests.toString(), path, provider);
  });

  const overrides = isMobile ? dialogOverridesMobile : dialogOverridesDesktop;

  return (
    <DialogV2 isOpen={isOpen} onClose={onClose} enableDividers={true} overrides={overrides} size="sm">
      <DialogV2.Header typographyVariant="hed4" textAlign="center">
        <DialogV2.CloseButton zIndex="modal" />
      </DialogV2.Header>
      <DialogV2.Body>
        <Flex padding={'0px 32px 40px 32px'} flexDirection="column" justifyContent="center">
          <SpacingStack spacing={2} alignItems={'center'}>
            <Flex
              as="a"
              target="_blank"
              cursor="pointer"
              href={getProviderPath('joy')}
              onClick={handleClick('joy', getProviderPath('joy'))}
              width={244}
              border={'1px solid'}
              borderColor={'gray5'}
              flexDirection={'column'}
              alignContent={'center'}
              justifyContent={'center'}
              alignItems={'center'}
              paddingY={26}
              paddingX={16}
              borderRadius={2}
              gap={16}
              _hover={{
                backgroundColor: 'gray1',
                borderColor: 'gray6'
              }}
              _active={{
                backgroundColor: 'gray2',
                borderColor: 'gray7'
              }}
            >
              <JoyLogo width="77px" color="#330066"></JoyLogo>
              <TextV2 textAlign={'center'} fontFamily={'Inter UI'} fontSize={15} fontWeight={500} lineHeight={'22.5px'}>
                {t('joyChoiceSubtitle')()}
              </TextV2>
            </Flex>
            {placesAsPerLocation.map(place => (
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                paddingY={pxToRem(21.14)}
                paddingX={pxToRem(61.5)}
                border="1px solid"
                borderColor="gray5"
                borderRadius={1}
                width={pxToRem(244)}
                cursor="pointer"
                _hover={{ backgroundColor: 'gray1', borderColor: 'gray6' }}
                _active={{ backgroundColor: 'gray2', borderColor: 'gray7' }}
                aria-label={t('placeTitle')({ site: place.site })}
                key={place.id}
                as="a"
                target="_blank"
                href={getProviderPath(place.id)}
                onClick={handleClick(place.id, getProviderPath(place.id))}
              >
                {place.logo}
              </Flex>
            ))}
          </SpacingStack>
        </Flex>
      </DialogV2.Body>
    </DialogV2>
  );
};

const BookingAssistant = (props: BookingAssistantProps) => {
  return <BookingAssistantContent {...props} />;
};

BookingAssistant.displayName = 'BookingAssistant';

export { BookingAssistant };
