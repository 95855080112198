import { AIBackgroundPromptPayload, StationeryTemplateCategoryEnum } from '@graphql/generated';
import { LayerData } from './components/Layer.types';
import { LocationState } from 'history';

export type PrintDesignNavigationStep = 'cardFront' | 'cardBack' | 'envelope';
export type DigitalDesignNavigationStep = 'cardFront' | 'cardBack';
export type DesignNavigationStep = PrintDesignNavigationStep | DigitalDesignNavigationStep;

export type PrintNavigationStep = PrintDesignNavigationStep | 'recipients' | 'review' | 'postCheckout';
export type DigitalNavigationStep = DigitalDesignNavigationStep | 'review' | 'postCheckout' | 'success';

export type NavigationStep = PrintNavigationStep | DigitalNavigationStep;

export interface NavigationContext<T extends string | number | symbol> {
  currentStep: T;
  steps: Record<
    T,
    {
      /**
       * A simple boolean that reflects whether a user has gone through this step.
       * The step component itself should employ business logic to determine if the step was actually completed.
       */
      hasCompleted: boolean;
    }
  >;

  navigateForward: () => void;

  goToStep: (step: T, state?: LocationState) => void;

  draftId: string;
}

/**
 * Not finalized data structure
 */
export type DraftData = {
  config: ThemeJson;
  template: {
    name?: string | null | undefined;
    themeId: string;
  };
  stationeryTemplateCategory: StationeryTemplateCategoryEnum;
};

export type Envelope = {
  layers: Array<LayerData>;
  layout: CardEnvelopeReturnAddressLayout;
  recipientAddressLayout?: CardEnvelopeRecipientAddressLayout;
  recipientAddressSelection?: CardEnvelopeRecipientAddressSelection;
  recipientAddressLayers?: Array<LayerData>;
  recipientIdList?: Array<string>;
  recipientIdWithAddressListLength?: number;
  design: CardEnvelopeDesign;
};

export interface PageDimensions {
  readonly width: number;
  readonly height: number;
  readonly cutMargin: number;
  readonly safeZoneMargin: number;
}

export interface ThemeJson {
  name: string;
  $schema: string;
  customizations: Pick<CardPriceModifiers, 'size' | 'paperType' | 'shape' | 'quantity' | 'delivery' | 'promoCode'> & {
    returnAddress?: CardAddress;
    deliveryAddress?: CardAddress;
    billingAddress?: CardAddress;
  };
  digitalCustomizations?: DigitalCardPriceModifiers & {
    backgroundColor?: string;
  };
  card: {
    layout: PageDimensions;
    front: {
      dimensions?: PageDimensions;
      fill: string;
      layers: Array<LayerData>;
    };
    back: {
      dimensions?: PageDimensions;
      fill: string;
      layers: Array<LayerData>;
      layout: string;
    };
    envelope: Envelope;
  };
  imageHistory: Array<ImageHistoryElement>;
}

export interface ImageHistoryElement {
  type: string;
  url: string;
}

export interface ImageHistoryUpload extends ImageHistoryElement {
  type: 'upload';
}

export interface ImageHistoryVariation extends ImageHistoryElement {
  type: 'ai_prompt';
  promptPayload: AIBackgroundPromptPayload;
  baseUrl: string | null;
  resourceID: string | null;
}

export enum CardSize {
  classic = 'classic'
}

export enum CardPaperType {
  signature = 'standard',
  pearlescent = 'pearlescent',
  doubleThick = 'doubleThick',
  smooth = 'smooth',
  tripleThick = 'tripleThick'
}

export enum CardDieCut {
  rounded = 'rounded',
  rectangle = 'rectangle',
  arch = 'arch'
}

export enum CardDelivery {
  economy = 'economy',
  standard = 'standard',
  express = 'express',
  upsGroundCanadaStandard = 'ups_ground_canada_standard',
  upsExpressSaver = 'ups_express_saver',
  upsInternationalExpressSaver = 'ups_international_express_saver'
}

export enum CardEnvelopeReturnAddressLayout {
  front = 'front',
  back = 'back',
  none = 'none'
}

export enum CardEnvelopeRecipientAddressLayout {
  front = 'front',
  none = 'none'
}

export enum CardEnvelopeRecipientAddressSelection {
  later = 'later',
  csv = 'csv',
  now = 'now'
}

export enum CardEnvelopeDesign {
  traditional = 'traditional',
  script = 'script',
  modern = 'modern'
}

export enum DigitalDeliveryFeatures {
  email = 'email',
  email_200 = 'email_200',
  email_500 = 'email_500',
  email_1000 = 'email_1000',
  sms = 'sms',
  link = 'link',
  internationalsms = 'internationalsms',
  internationalsms_5 = 'internationalsms_5'
}

export interface PriceConfig {
  quantity: {
    [Key in StationeryTemplateCategoryEnum]: Record<
      number,
      {
        quantity: number;
        totalPriceInMinorUnits: number;
        // individualPrice: number;
        individualPriceString: string;
        totalPriceString: string;
      }
    >;
  };
  shape: Record<
    CardDieCut,
    {
      code: string | undefined;
      label: string;
      individualPriceString: string | undefined;
      getTotalPriceStringByQuantity: (quantity: number) => string | undefined;
      getTotalPriceByQuantityInMinorUnits: (quantity: number) => number;
    }
  >;
  paperType: {
    [Key in CardPaperType]: Record<
      number,
      {
        quantity: number;
        totalPriceInMinorUnits: number;
        individualPriceString: string;
        totalPriceString: string;
      }
    >;
  };
  delivery: Record<CardDelivery, number>;
  returnAddress: Record<
    CardEnvelopeReturnAddressLayout,
    {
      individualPriceString: string | undefined;
      getTotalPriceStringByQuantity: (quantity: number) => string;
      getTotalPriceByQuantityInMinorUnits: (quantity: number) => number;
    }
  >;
  recipientAddress?: Record<
    CardEnvelopeRecipientAddressLayout,
    {
      individualPriceString: string | undefined;
      getTotalPriceStringByQuantity: (quantity: number) => string;
      getTotalPriceByQuantityInMinorUnits: (quantity: number) => number;
    }
  >;
}

export interface CardPriceModifiers {
  quantity: number;
  size: CardSize;
  paperType: CardPaperType;
  shape: CardDieCut;
  backLayout: string;
  returnAddress: CardEnvelopeReturnAddressLayout;
  recipientAddress?: CardEnvelopeRecipientAddressLayout;
  delivery?: CardDelivery;
  // Only storing the promo code value and not the config
  promoCode?: string;
}

export type DeliveryPackageKey = 'basic' | 'premium' | 'diamond';

export interface DigitalCardPriceModifiers {
  // deliveryFeatures?: DigitalDeliveryFeatures[];
  quantity?: string;
  deliveryPackageKey?: DeliveryPackageKey;
}

export interface CardAddress {
  name: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  countryCode: string;
}
