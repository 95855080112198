import { AccommodationHotelsQuery, AccommodationPlaceType, ReservedRoomBlockStatus } from '@graphql/generated';
import { AccommodationItemList, TravelMapBlockType } from '../TravelMap.types';

interface DetailAddressArgs {
  address?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
}

export const getHotelAddress = ({ address, city, state, postalCode, country }: DetailAddressArgs) => {
  const fullAddress = `${address ?? ''}${city ? ', ' + city : ''}${state || postalCode ? ', ' : ''}${state ?? ''}${postalCode ? ' ' + postalCode : ''}${
    country ? ', ' + country : ''
  }`;
  return fullAddress;
};

export const generateAccommodationList: (accommodation: AccommodationHotelsQuery['eventByName']) => AccommodationItemList[] = accommodation => {
  return [
    ...(accommodation?.hotelRoomBlocks?.reservedRoomBlocks?.filter(roomBlock => roomBlock.status === ReservedRoomBlockStatus.active) || []).map(roomblock => ({
      id: roomblock.id,
      type: TravelMapBlockType.travelMapAccommodationsRoomblock,
      isFavorite: roomblock.isFavorite,
      photoUrl: roomblock.photo?.url || '',
      address: roomblock.address || '',
      cutoffDate: roomblock.cutoffDate || '',
      displayName: roomblock.displayName || '',
      status: roomblock.status,
      isPhotoHidden: false,
      lat: roomblock.latitude,
      lng: roomblock.longitude,
      rating: roomblock.starRating,
      description: roomblock.note,
      price: roomblock.pricePerNight,
      strikeoutPrice: roomblock.strikeoutPricePerNight,
      websiteUrl: roomblock.hotelMoreInfoUrl ?? undefined
    })),
    ...(accommodation?.accommodationPlacesV2 || []).map(accPlaceV2 => ({
      id: accPlaceV2.id,
      type: accPlaceV2.type === AccommodationPlaceType.hotel ? TravelMapBlockType.travelMapAccommodationsHotel : TravelMapBlockType.travelMapAccommodationsCustom,
      isFavorite: false,
      photoUrl: accPlaceV2.customPhoto?.url || accPlaceV2.photo?.url || '',
      isPhotoHidden: !!accPlaceV2.isPhotoHidden,
      address:
        accPlaceV2.customAddress ||
        getHotelAddress({ address: accPlaceV2.address, city: accPlaceV2.city, state: accPlaceV2.state, postalCode: accPlaceV2.postalCode, country: accPlaceV2.country }) ||
        '',
      cutoffDate: '',
      displayName: accPlaceV2.customName || accPlaceV2.name || '',
      lat: accPlaceV2.lat,
      lng: accPlaceV2.long,
      description: accPlaceV2.description,
      phone: accPlaceV2.phone,
      placeId: accPlaceV2.zhId || accPlaceV2.googlePlaceId,
      websiteUrl: accPlaceV2.customUrl || accPlaceV2.url || undefined
    }))
  ];
};
