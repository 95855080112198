import { CardEnvelopeDesign, CardEnvelopeReturnAddressLayout, ThemeJson } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { LayerData } from '@apps/card/routes/CardCustomizer/components/Layer.types';
import TRADITIONAL_FRONT from '@apps/card/routes/CardCustomizer/steps/Envelope/templates/traditional.front.return.json';
import { CardDashboardStationeryTemplatesContainerQuery, StationeryDesignGalleryTemplateFragment, StationeryTemplateCategoryEnum, ThemeVariationType } from '@graphql/generated';
import { DigitalDesignTemplateCategory, StationeryTemplate, ValidDigitalDesignApiData } from './DesignsGallery.types';
import { SAVE_THE_DATE_PHOTO_THEMES } from '@apps/saveTheDate/constants';
import { PLACEHOLDER_TEMPLATE } from '@apps/card/routes/CardCustomizer/components/CardBackChoiceGroup';
import { isEmpty } from 'lodash-es';

export const WITHOUT_PHOTO_STATIONERY_TAG = 'Photo:withoutphoto';
export const PREMIUM_TEMPLATE_STATIONERY_TAG = 'Price:premium';
export const BASIC_TEMPLATE_STATIONERY_TAG = 'Price:basic';

export function applyDefaultBackTemplate(themeJSON: ThemeJson) {
  if (isEmpty(themeJSON.card.back)) {
    themeJSON.card.back = PLACEHOLDER_TEMPLATE;
  }
}

// For MVP, we baked the default card back and envelope into every template.
// Moving forward, new templates will not contain a default card back and envelope.
// This fills/overwrites in the default data, so it doesn't matter what was baked into the template.
export function resetCardBackAndEnvelope(themeJSON: ThemeJson) {
  themeJSON.card.back = PLACEHOLDER_TEMPLATE;
  themeJSON.card.envelope.layout = CardEnvelopeReturnAddressLayout.front;
  themeJSON.card.envelope.design = CardEnvelopeDesign.traditional;
  themeJSON.card.envelope.layers = TRADITIONAL_FRONT as LayerData[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidDigitalDesign = (digitalDesign: any): digitalDesign is ValidDigitalDesignApiData => {
  return !!digitalDesign && !!digitalDesign.themeId && !!digitalDesign.thumbnailUrl && !!digitalDesign.theme?.name && !!digitalDesign.theme?.id;
};

const STATIONERY_TEMPLATE_CATEGORY_TO_THEME_VARIATION_TYPE: Record<StationeryTemplateCategoryEnum, ThemeVariationType | null> = {
  saveTheDate: ThemeVariationType.savethedate,
  invitation: ThemeVariationType.invite,
  thankYou: null,
  holiday: null,
  enclosure: null
};

const JOY_MARKETING_VENDOR = 'Joy';

type DesignServiceThemeData = {
  id: string;
  themeId: string;
  name: string;
  marketingData: CardDashboardStationeryTemplatesContainerQuery['ecardDesigns'][number]['marketingData'];
};

export const findMatchingMarketingData = (theme: DesignServiceThemeData, category: StationeryTemplateCategoryEnum) => {
  return theme.marketingData?.find(
    data =>
      // Ignoring partner designs for now
      data.vendor === JOY_MARKETING_VENDOR &&
      // `marketingData` is an array of related designs
      // eg. if `themeId` === 'arch_ii', marketingData will include 'arch_i', 'arch_ii', 'arch_iii'
      data.variantThemeId === theme.themeId &&
      // In modern ecard customizer, we're currently requesting all themes regardless of variation type --
      // which means that both save the date and invitations show the same set of themes.
      //
      // However, the theme tiles in the ecard customizer show a generic thumbnail (no text/content)
      // and this won't work in the card gallery because we need thumbnails with content preview.
      data.variationType === STATIONERY_TEMPLATE_CATEGORY_TO_THEME_VARIATION_TYPE[category]
  );
};

export const processInitialQueryData = (data: CardDashboardStationeryTemplatesContainerQuery | undefined, category: StationeryTemplateCategoryEnum): Array<StationeryTemplate> => {
  const allPrintableThemeIdsSet =
    data?.stationeryTemplatesContainer.pagedResults.edges.reduce((acc, curr) => {
      acc.set(curr.node.themeId, curr.node);
      return acc;
    }, new Map<string, StationeryDesignGalleryTemplateFragment>()) || new Map<string, StationeryDesignGalleryTemplateFragment>();

  const templatesWithMatchingDigitalDesignMap = new Map<string, ValidDigitalDesignApiData>();
  const digitalOnlyDesigns: StationeryTemplate[] = [];

  if (category === StationeryTemplateCategoryEnum.saveTheDate) {
    // TODO: photo ecards should be sent from card_service
    // https://withjoy.atlassian.net/browse/PRNT-1107
    SAVE_THE_DATE_PHOTO_THEMES.forEach(theme => {
      const printableThemeConfig = allPrintableThemeIdsSet.get(theme.themeId);
      if (printableThemeConfig) {
        templatesWithMatchingDigitalDesignMap.set(theme.themeId, {
          themeId: theme.themeId,
          thumbnailUrl: theme.thumbnailUrl,
          category,
          name: theme.name,
          isPremiumDesign: printableThemeConfig.premium
        });
      }
    });
  }

  if (data?.ecardDesigns) {
    data.ecardDesigns.forEach(design => {
      const designThemeId = design.themeId;
      const designName = design.name;
      const digitalDesignConfig = findMatchingMarketingData(design, category);

      if (digitalDesignConfig) {
        const thumbnailUrl = digitalDesignConfig.imageUrl;
        const printableThemeConfig = allPrintableThemeIdsSet.get(designThemeId);
        // Check if the digital design is also printable
        if (printableThemeConfig) {
          // if printable, track the digital design
          templatesWithMatchingDigitalDesignMap.set(designThemeId, {
            themeId: designThemeId,
            thumbnailUrl,
            name: designName,
            category: category as DigitalDesignTemplateCategory,
            isPremiumDesign: printableThemeConfig.premium
          });
        } else {
          // Otherwise, store the digital design to be placed at the end of the list
          digitalOnlyDesigns.push({
            id: design.id,
            format: 'digital',
            themeId: designThemeId,
            category,
            approved: true,
            enabled: true,
            name: designName,
            thumbnailUrl: null,
            digitalDesignThumbnailUrl: thumbnailUrl,
            premium: false,
            tags: [],
            mappedTags: [],
            isFavorite: false
          });
        }
      }
    });
  }

  /**
   * Mapping tag names to composite codes so we can derive `StationeryTemplate.mappedTags`
   */
  const tagNameToCompositeCodes: Record<string, string> =
    data?.stationeryTags?.reduce((acc, tag) => {
      acc[tag.name] = `${tag.category}:${tag.name}`;
      return acc;
    }, {} as Record<string, string>) || {};

  const processedInitialPrintableTemplates: Array<StationeryTemplate> =
    data?.stationeryTemplatesContainer.pagedResults.edges.map(({ node: template }) => {
      const maybeDigitalDesignConfig = templatesWithMatchingDigitalDesignMap.get(template.themeId);

      let isPhotoTemplate = false;
      const mappedTags = template.tags
        .map(tag => {
          if (tag === 'photo') {
            isPhotoTemplate = true;
          }

          return tagNameToCompositeCodes[tag];
        })
        .filter(Boolean);

      if (!isPhotoTemplate) {
        mappedTags.push(WITHOUT_PHOTO_STATIONERY_TAG);
      }

      if (template.premium || !maybeDigitalDesignConfig) {
        mappedTags.push(PREMIUM_TEMPLATE_STATIONERY_TAG);
      } else {
        mappedTags.push(BASIC_TEMPLATE_STATIONERY_TAG);
      }

      return {
        ...template,
        // Mapping tags to composite codes for applying filters in `useApplyFiltersToTemplates`
        mappedTags,
        format: maybeDigitalDesignConfig ? 'paperAndDigital' : 'paper',
        digitalDesignThumbnailUrl: maybeDigitalDesignConfig?.thumbnailUrl
      };
    }) || [];

  return [...processedInitialPrintableTemplates, ...digitalOnlyDesigns];
};
