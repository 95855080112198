import { useEffect } from 'react';
import globalWindow from '@shared/core/globals';
import { useCardCustomizerTelemetry } from '../routes/CardCustomizer/CardCustomizer.telemetry';
import { FONT_FAMILIES, SELF_HOSTED, SELF_HOSTED_SYSTEM } from '../fonts';

const FONT_HOST = 'https://withjoy.com/assets/public/messaging/fonts/';

function loadFont(family: string, onError: (family: string, error: unknown) => void) {
  const [name] = family.split(':'); // `family` may be of format "Literata:400italic"
  const woff = SELF_HOSTED[name] ?? SELF_HOSTED_SYSTEM[name];
  if (woff) {
    void (async () => {
      try {
        const font = new FontFace(name, `url('${FONT_HOST}${woff}')`);
        await font.load();
        // document.fonts() is not supported in Edge 17 (released April 2018)
        // eslint-disable-next-line compat/compat
        document.fonts.add(font);
      } catch (error) {
        onError(name, error);
      }
    })();
    return;
  }

  // Look in the Network Tab for URLs of this pattern to inspect:
  // https://fonts.googleapis.com/css?family=Literata:400italic
  globalWindow.WebFont?.load({
    google: {
      families: [family]
    }
  });
}

export function useFont(family: string) {
  const { loadFontError } = useCardCustomizerTelemetry();
  useEffect(() => loadFont(family, loadFontError), [family, loadFontError]);
}

// Not a permanent solution, using this for MVP internal launch
// For TextLayerToolbar font picker preview, not for individual layers.
export function useAllFonts() {
  const { loadFontError } = useCardCustomizerTelemetry();
  useEffect(() => {
    for (const family of FONT_FAMILIES) {
      loadFont(family, loadFontError);
    }
  }, [loadFontError]);
}
